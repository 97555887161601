import React, { useCallback, useState, useContext } from "react";
import { useTranslation } from "react-i18next";

import ServiceList from "components/shift-personel-service/ShiftPersonelServicesList";
import ServiceItem from "components/shift-personel-service/ShiftPersonelServiceItem";
import NewServiceItem from "components/shift-personel-service/NewShiftPersonelServiceItem";
import Button from "shared/components/FormElements/Button";
import ErrorModal from "shared/components/UIElements/ErrorModal";
import LoadingSpinner from "shared/components/UIElements/LoadingSpinner";
import { useHttpClient } from "shared/hooks/http-hook";
import { StoreContext } from "shared/context/store-context";
import "./Admin.css";

const ShiftPersonelServices = () => {
  const { t } = useTranslation();
  const { shiftPersonelServices, storeReady } = useContext(StoreContext);
  const { isLoading, error, clearError } = useHttpClient();
  const [selectedItemId, setSelectedItemId] = useState();
  const [selectedItemShow, setSelectedItemShow] = useState(false);
  const [newItemShow, setNewItemShow] = useState(false);

  const ItemType = "shiftpersonelservice";
  const ItemSelectHandler = useCallback((itemid) => {
    setSelectedItemShow(true);
    setNewItemShow(false);
    setSelectedItemId(itemid);
  }, []);

  const ItemUpdateHandler = useCallback((itemid) => {
    setNewItemShow(false);
    setSelectedItemId(itemid);
  }, []);

  const newItemHandler = useCallback(() => {
    setSelectedItemShow(false);
    setNewItemShow(true);
  }, []);

  const closeNewItemHandler = useCallback(() => {
    setNewItemShow(false);
    setSelectedItemShow(false);
    setSelectedItemId(null);
  }, []);

  if (isLoading || !storeReady) {
    return (
      <div className="center">
        <LoadingSpinner />
      </div>
    );
  }
  return (
    <React.Fragment>
      <ErrorModal error={error} onClear={clearError} />

      <div className="adminNavigationHeader">
        <Button danger size="small" onClick={newItemHandler}>
          {t(`${ItemType}.createNewItem`)}
        </Button>
      </div>
      <div className="adminpageContainer">
        {!isLoading && shiftPersonelServices && (
          <div className="adminListContainer">
            <ServiceList items={shiftPersonelServices} onItemSelect={ItemSelectHandler} selectedId={selectedItemId} />
          </div>
        )}
        {selectedItemId && selectedItemShow && (
          <div className="adminUpdateContainer">
            <ServiceItem
              itemid={selectedItemId}
              onItemUpdate={ItemUpdateHandler}
              onClose={() => setSelectedItemShow(false)}
            ></ServiceItem>
          </div>
        )}
        {newItemShow && (
          <div className="adminUpdateContainer">
            <NewServiceItem gotoItem={ItemSelectHandler} onClose={closeNewItemHandler}></NewServiceItem>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default ShiftPersonelServices;
