import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid";
import { ThemeProvider } from "@mui/material/styles";
import { StoreContext } from "shared/context/store-context";
import { AuthContext } from "shared/context/auth-context";

const UsersList = (props) => {
  const { t } = useTranslation();
  const { schoolOptions, standartPersonelServices } = useContext(StoreContext);
  const auth = useContext(AuthContext);

  const visibilityJSON = localStorage.getItem("users_visibility");
  const visibilityModel = visibilityJSON ? JSON.parse(visibilityJSON) : {};
  const [columnVisibilityModel, setColumnVisibilityModel] = useState(visibilityModel);
  const filterJSON = localStorage.getItem("users_filter");
  const initialFilterModel = filterJSON ? JSON.parse(filterJSON) : { items: [] };
  const [filterModel, setFilterModel] = useState(initialFilterModel);

  let usertypestoshow = props.usertypes;

  // if (isAdmin === false) {
  //   usertypestoshow.pop();
  // }

  const columns = [
    { field: "name", headerName: t("name"), width: 300 },
    {
      field: "getonservice",
      headerName: "Sabah Servisi",
      width: 300,
      valueGetter: (params) => {
        try {
          return (
            standartPersonelServices.find((service) => service.type === "100" && service?.users.includes(params.row.id))
              ?.name || "-"
          );
        } catch {
          return "-";
        }
      },
    },
    {
      field: "getoffservice",
      headerName: "Akşam Servisi",
      width: 300,
      valueGetter: (params) => {
        try {
          return (
            standartPersonelServices.find((service) => service.type === "200" && service?.users.includes(params.row.id))
              ?.name || "-"
          );
        } catch {
          return "-";
        }
      },
    },
    { field: "phonenumber", headerName: t("phone"), width: 200 },
    {
      field: "usertype",
      headerName: t("userType"),
      width: 150,
      valueGetter: (params) =>
        usertypestoshow.find((item) => item.value.toString() === params.row.usertype)?.label.toString(),
    },
    {
      field: "isshift",
      headerName: t("isshift"),
      width: 200,
      valueGetter: (params) => (params?.row?.isshift ? t("yes") : t("no")),
    },
    {
      field: "school",
      headerName: t("client"),
      width: 300,
      valueGetter: (params) =>
        schoolOptions.find((school) => school?.value?.toString() === params.row?.school?.toString())?.label?.toString(),
    },
  ];

  const isShiftUserColumn = {
    field: "isshiftuser",
    headerName: t("isShiftUser"),
    width: 150,
    valueGetter: (params) => (params.row.isshiftuser ? t("yes") : t("no")),
  };

  const OnSelectionChangeHandler = (event) => {
    props.onItemSelect(event[0]);
  };

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarExport csvOptions={{ utf8WithBom: true }} />
      </GridToolbarContainer>
    );
  }

  return (
    <div className="itemListContainer">
      <ThemeProvider theme={auth.theme}>
        <DataGrid
          columnVisibilityModel={columnVisibilityModel}
          onColumnVisibilityModelChange={(newModel) => {
            localStorage.setItem("users_visibility", JSON.stringify(newModel));
            setColumnVisibilityModel(newModel);
          }}
          filterModel={filterModel}
          onFilterModelChange={(newModel) => {
            localStorage.setItem("users_filter", JSON.stringify(newModel));
            setFilterModel(newModel);
          }}
          key={props.items}
          density="compact"
          components={{
            Toolbar: CustomToolbar,
          }}
          rows={props.items}
          columns={columns}
          pageSize={100}
          getRowId={(r) => r.id}
          onSelectionModelChange={OnSelectionChangeHandler}
          selectionModel={props?.selectedId ? [props.selectedId] : []}
          paginationModel={"server"}
          keepNonExistentRowsSelected
        />
      </ThemeProvider>
    </div>
  );
};

export default UsersList;
