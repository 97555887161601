import React, { useContext, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

// #region Components
import Input from "shared/components/FormElements/Input";
import Button from "shared/components/FormElements/Button";
import CustomSelect from "shared/components/FormElements/Select";
import LoadingSpinner from "shared/components/UIElements/LoadingSpinner";
import ErrorModal from "shared/components/UIElements/ErrorModal";
import { days, days_en } from "shared/util/types";
// #endregion Components

// #region Utils
import { VALIDATOR_REQUIRE, VALIDATOR_TIME } from "shared/util/validators";
// #endregion Utils

// #region Hooks
import { useForm } from "shared/hooks/form-hook";
import { useHttpClient } from "shared/hooks/http-hook";
import { AuthContext } from "shared/context/auth-context";
import { StoreContext } from "shared/context/store-context";
// #endregion Hooks

const NewShiftItem = (props) => {
  const { t } = useTranslation();
  const auth = useContext(AuthContext);
  const { InsertShift } = useContext(StoreContext);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [itemGenerated, setItemGenerated] = useState(false);
  const refGeneratedShiftId = useRef();
  const language = (i18next?.language || window?.localStorage?.i18nextLng || "en").substring(0, 2);

  const ItemType = "shift";
  const APIEndPoint = "shifts";

  const itemSubmitHandler = async (event) => {
    event.preventDefault();
    try {
      const responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/${APIEndPoint}`,
        "POST",
        JSON.stringify({
          name: formState.inputs.name.value,
          day: formState.inputs.day.value,
          starttime: formState.inputs.starttime.value,
          endtime: formState.inputs.endtime.value,
          school: auth.school,
        }),
        {
          "Content-Type": "application/json",
          Authorization: "Bearer " + auth.token,
        }
      );

      if (responseData[ItemType]) {
        InsertShift(responseData[ItemType]);
        refGeneratedShiftId.current = responseData[ItemType].id;
        setItemGenerated(true);
      }
    } catch (err) {}
  };

  const [formState, inputHandler] = useForm(
    {
      name: { value: "", isValid: false },
      day: { value: "", isValid: false },
      starttime: { value: "", isValid: false },
      endtime: { value: "", isValid: false },
    },
    false
  );

  const goToItem = () => {
    props.gotoItem(refGeneratedShiftId.current);
  };

  const close = () => {
    setItemGenerated(false);
    props.onClose();
  };

  if (isLoading) {
    return (
      <div className="center">
        <LoadingSpinner />
      </div>
    );
  }

  if (itemGenerated) {
    return (
      <div className="center generate-success">
        <h4>{t(`${ItemType}.itemAddedSuccessfully`)}</h4>
        <Button onClick={goToItem}> {t(`${ItemType}.goToNewItem`)}</Button>{" "}
        <Button danger onClick={() => setItemGenerated(false)}>
          {t(`${ItemType}.createNewItem`)}
        </Button>{" "}
        <Button danger onClick={close}>
          {t("close")}
        </Button>{" "}
      </div>
    );
  }

  return (
    <React.Fragment>
      <ErrorModal error={error} onClear={clearError} />
      <form className="item-form" onSubmit={itemSubmitHandler}>
        {isLoading && <LoadingSpinner asOverlay />}
        <div className="new-button-container">
          <h3 style={{ margin: 0 }}>{t(`${ItemType}.createNewItem`)}</h3>
          <Button type="button" size="small" danger onClick={props.onClose}>
            &#60; {t("close")}
          </Button>
        </div>
        <Input
          id="name"
          element="input"
          type="text"
          label={t("name")}
          validators={[VALIDATOR_REQUIRE()]}
          errorText={t("requireField", { item: t("name") })}
          onInput={inputHandler}
        />
        <CustomSelect
          options={language === "en" ? days_en : days}
          id="day"
          onInput={inputHandler}
          label={t("day")}
        ></CustomSelect>
        <Input
          id="starttime"
          element="input"
          type="text"
          label={t("startTime")}
          validators={[VALIDATOR_TIME(formState.inputs.starttime.value)]}
          errorText={t("invalidTimeField", { item: t("startTime") })}
          onInput={inputHandler}
        />
        <Input
          id="endtime"
          element="input"
          type="text"
          label={t("endTime")}
          validators={[VALIDATOR_TIME(formState.inputs.endtime.value)]}
          errorText={t("invalidTimeField", { item: t("endTime") })}
          onInput={inputHandler}
        />

        <Button type="submit" disabled={!formState.isValid}>
          {t(`${ItemType}.createNewItem`)}
        </Button>
      </form>
    </React.Fragment>
  );
};

export default NewShiftItem;
