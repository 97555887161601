import React, { useState, useRef, useEffect, useContext } from "react";
import { useDrop } from "react-dnd";
import { ItemTypes } from "./types";
import AirportIcon from "@mui/icons-material/Flight";
import Person from "./Person";
import GoogleMapReact from "google-map-react";
import RoomIcon from "@mui/icons-material/Room";
import Marker from "shared/components/UIElements/Marker";
import CustomSelect from "shared/components/FormElements/Select";
import { AIRPORT_ID, AIRPORT_LAT, AIRPORT_LNG } from "shared/util/location";
import { subtractSecondsFromDate } from "shared/util/time";
import { StoreContext } from "shared/context/store-context";
import { VALIDATOR_REQUIRE } from "shared/util/validators";
import { useForm } from "shared/hooks/form-hook";
import { CheckTimeString } from "shared/util/time";
import "./dragger.css";

const Vehicle = ({
  id,
  name,
  people,
  airportTime,
  driver,
  onDrop,
  distances,
  transferType,
  onPersonUp,
  onPersonDown,
  onOptimize,
  onDeleteVehicle,
  onSaveResult,
}) => {
  const store = useContext(StoreContext);

  const mapREF = useRef(null);
  const mapsREF = useRef(null);
  const polylinesREF = useRef([]);
  const polyLineLabelsREF = useRef([]);
  const optimizedREF = useRef(false);
  const [calculatedPeople, setCalculatedPeople] = useState([]);
  const [airportInfo, setAirportInfo] = useState({});
  const [transferName, setTransferName] = useState(name);

  const [{ canDrop, isOver }, drop] = useDrop({
    accept: ItemTypes.PASSENGER,
    drop: (item) => onDrop(item.id, id),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
    type: ItemTypes.VEHICLE,
  });

  const isActive = canDrop && isOver;

  const handleApiLoaded = (map, maps) => {
    mapREF.current = map;
    mapsREF.current = maps;
    drawPolylines();
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const drawPolylines = () => {
    if (!mapREF.current || !mapsREF.current) {
      return;
    }

    //write above with for loop
    for (var k = 0; k < polylinesREF.current.length; k++) {
      polylinesREF.current[k].setMap(null);
    }

    for (var l = 0; l < polyLineLabelsREF.current.length; l++) {
      polyLineLabelsREF.current[l].setMap(null);
    }

    const currentPolylines = [];
    const currentPeople = [];
    for (var i = 0; i < people.length; i++) {
      // eslint-disable-next-line no-loop-func
      let person = people[i];
      let nextPersonId = null;
      if (transferType.toString() === "100") {
        if (i < people.length - 1) {
          nextPersonId = people[i + 1].id;
        } else {
          nextPersonId = AIRPORT_ID;
        }
      }

      if (transferType.toString() === "200") {
        if (i === 0) {
          person = { id: AIRPORT_ID, name: "Havaalanı", lat: AIRPORT_LAT, lng: AIRPORT_LNG };
          nextPersonId = people[i].id;
        } else {
          person = people[i - 1];
          nextPersonId = people[i].id;
        }
      }

      const currentDistance = distances.find((distance) => distance.from === person.id && distance.to === nextPersonId);
      const currentPolygons = currentDistance.data.steps.map((step) => {
        return [
          { lat: step.start_location.lat, lng: step.start_location.lng },
          { lat: step.end_location.lat, lng: step.end_location.lng },
        ];
      });

      const currentDistanceDistance = currentDistance.data.distance.text;
      const currentDistanceDuration = currentDistance.data.duration.text.replace("mins", "dk").replace("min", "dk");
      const currentDistanceDurationValue = currentDistance.data.duration.value;
      const currentDistanceDistanceValue = currentDistance.data.distance.value;

      const currentDistanceLabel = currentDistanceDistance + " (" + currentDistanceDuration + ")";
      // const currentDistanceLabelPosition = {
      //   lat: currentPolygons[Math.ceil(currentPolygons.length / 4)][0].lat,
      //   lng: currentPolygons[Math.ceil(currentPolygons.length / 4)][0].lng,
      // };

      for (var j = 0; j < currentPolygons.length; j++) {
        var polyline = new mapsREF.current.Polyline({
          path: currentPolygons[j],
          strokeColor: "#FF0000",
          strokeOpacity: 1,
          strokeWeight: 2,
          // fillColor: "#FF0000",
          fillOpacity: 0,
          icons: [
            {
              icon: { path: mapsREF.current.SymbolPath.FORWARD_CLOSED_ARROW },
              offset: "100%",
              repeat: "30px",
            },
          ],
        });

        currentPolylines.push(polyline);
        polyline.setMap(mapREF.current);
      }

      if (transferType.toString() === "200" && i === 0) {
        setAirportInfo({
          polylineLabel: currentDistanceLabel,
          distanceLabel: currentDistanceDistance,
          durationLabel: currentDistanceDuration,
          durationValue: currentDistanceDurationValue,
          distanceValue: currentDistanceDistanceValue,
        });
        continue;
      }
      person.polylineLabel = currentDistanceLabel;
      person.durationLabel = currentDistanceDuration;
      person.distanceLabel = currentDistanceDistance;
      person.durationValue = currentDistanceDurationValue;
      person.distanceValue = currentDistanceDistanceValue;
      currentPeople.push(person);
    }

    let pickupbeforeseconds = 0;
    if (transferType.toString() === 100) {
      for (var z = currentPeople.length - 1; z >= 0; z--) {
        const airporthourminute = airportTime.split(" ")[1].substring(0, 5);
        const additionTimeInSeconds = airporthourminute > "07:00" ? 300 : 0;
        pickupbeforeseconds += currentPeople[z].durationValue;
        currentPeople[z].pickupbeforeseconds = pickupbeforeseconds;
        currentPeople[z].pickupTime = subtractSecondsFromDate(
          airportTime,
          currentPeople[z].pickupbeforeseconds + additionTimeInSeconds
        )
          .split(" ")[4]
          .substring(0, 5);
      }
    }

    if (transferType === 200 && people.length > 0) {
      currentPeople.push(people[people.length - 1]);
    }

    polylinesREF.current = currentPolylines;
    //polyLineLabelsREF.current = currentPolylineLabels;
    setCalculatedPeople(currentPeople);

    onSaveResult(currentPeople, formState.inputs.newdriver.value, transferName);

    // const bounds = mapsREF.current.LatLngBounds();
    // mapREF.current.fitBounds(bounds);
  };

  useEffect(() => {
    drawPolylines();
    if (people.length && !optimizedREF.current) {
      onOptimize();
      optimizedREF.current = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [people]);

  const [formState, inputHandler] = useForm(
    {
      newdriver: { value: store.driverOptions.find((option) => option.value === driver).value, isValid: true },
    },
    true
  );

  const personPickupTimeChangeHandler = (event, personId) => {
    const person = calculatedPeople.find((person) => person.id === personId);
    person.pickupTime = event.target.value;
    setCalculatedPeople([...calculatedPeople]);

    //if(CheckTimeString(person.pickupTime))
    //{
    onSaveResult([...calculatedPeople], formState.inputs.newdriver.value, transferName);
    //}
  };

  const transferNameChangeHandler = (event) => {
    setTransferName(event.target.value);
    //if(event.target.value.length > 0)
    // {
    onSaveResult([...calculatedPeople], formState.inputs.newdriver.value, event.target.value);
    // }
  };

  return (
    <div
      ref={drop}
      style={{
        backgroundColor: isActive ? "#fbdd04" : "lightgray",
        padding: "10px",
        margin: "5px",
        marginBottom: "30px",
        borderRadius: "5px",
      }}
    >
      <div style={{ display: "flex", width: "100%", flexDirection: "row" }}>
        <div style={{ width: "25%", display: "flex", flexDirection: "column" }}>
          <input
            style={{
              marginBottom: "10px",
              height: "30px",
              width: "95%",
              borderRadius: "5px",
              paddingLeft: "5px",
              outlineColor: transferName?.length ? "black" : "red",
              borderColor: transferName?.length ? "black" : "red",
            }}
            type="text"
            id={id}
            onInput={(event) => transferNameChangeHandler(event)}
            value={transferName}
          />
          <div style={{ marginBottom: "5px" }}>
            Toplam Km:{" "}
            <b>
              {(
                (calculatedPeople.reduce((sum, obj) => sum + (obj?.distanceValue || 0), 0) +
                  (airportInfo?.distanceValue || 0)) /
                1000
              ).toFixed(1)}
            </b>
          </div>
          <div style={{ marginBottom: "10px" }}>
            Toplam Süre:{" "}
            <b>
              {(
                (calculatedPeople.reduce((sum, obj) => sum + (obj?.durationValue || 0), 0) +
                  (airportInfo?.durationValue || 0)) /
                60
              ).toFixed(0)}{" "}
              dk
            </b>
          </div>
          <div style={{ marginRight: "5px" }}>
            <CustomSelect
              options={store.driverOptions}
              id="newdriver"
              isMulti={false}
              onInput={(id, value) => {
                onSaveResult(calculatedPeople, value, transferName);
                inputHandler(id, value, true);
              }}
              label="Sürücü"
              errorText="En az 1 sürücü seçiniz."
              initialValue={store.driverOptions.find((option) => option.value === driver)}
              initialValid={true}
              validators={[VALIDATOR_REQUIRE()]}
            ></CustomSelect>
          </div>
          <div>
            {transferType.toString() === "200" && (
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  flexDirection: "column",
                  alignItems: "center",
                  placeContent: "center",
                }}
              >
                <div
                  style={{
                    backgroundColor: "#aaa",
                    fontWeight: "bold",
                    marginRight: "5px",
                    marginBottom: "10px",
                    borderRadius: "5px",
                    padding: "10px",
                    width: "80%",
                  }}
                >
                  Havaalanı - {airportTime}
                </div>
                <div style={{ marginBottom: "10px" }}>
                  {" "}
                  {">> "}
                  {airportInfo.polylineLabel} {" >>"}
                </div>
              </div>
            )}
            {calculatedPeople.map((person, index) => (
              <div
                style={{ display: "flex", width: "100%", flexDirection: "column", alignItems: "center" }}
                key={"calculatedPeople" + index}
              >
                <div style={{ display: "flex", width: "100%", flexDirection: "row", alignItems: "center" }}>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <button
                      style={{ alignSelf: "flex-start", backgroundColor: "black", padding: "5px", marginBottom: "5px" }}
                      type="button"
                      onClick={() => onPersonUp(person.id)}
                    >
                      <div className="arrow-up"></div>
                    </button>
                    <button
                      style={{ alignSelf: "flex-start", backgroundColor: "black", padding: "5px" }}
                      type="button"
                      onClick={() => onPersonDown(person.id)}
                    >
                      <div className="arrow-down"></div>
                    </button>
                  </div>
                  <Person key={person.id} id={person.id} name={index + 1 + ". " + person.name} />
                  {transferType.toString() === "100" && (
                    <input
                      style={{
                        height: "30px",
                        width: "20%",
                        borderRadius: "5px",
                        paddingLeft: "5px",
                        paddingRight: "5px",
                        marginRight: "5px",
                        outlineColor: CheckTimeString(person.pickupTime) ? "black" : "red",
                        borderColor: CheckTimeString(person.pickupTime) ? "black" : "red",
                      }}
                      type="text"
                      key={person.id + "-pickupTime"}
                      id={person.id + "-pickupTime"}
                      onInput={(e) => personPickupTimeChangeHandler(e, person.id)}
                      value={person.pickupTime}
                    />
                  )}
                </div>
                {person.polylineLabel && (
                  <div style={{ marginBottom: "10px" }}>
                    {" "}
                    {">> "}
                    {person?.polylineLabel} {" >>"}
                  </div>
                )}
              </div>
            ))}
            {transferType.toString() === "100" && (
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    backgroundColor: "#aaa",
                    fontWeight: "bold",
                    marginRight: "5px",
                    marginTop: "10px",
                    borderRadius: "5px",
                    padding: "10px",
                    width: "82%",
                  }}
                >
                  Havaalanı - {airportTime}
                </div>
              </div>
            )}
            <button
              style={{
                alignSelf: "flex-start",
                backgroundColor: "#830000",
                borderColor: "#830000",
                color: "white",
                height: "30px",
                borderRadius: "5px",
                marginBottom: "5px",
                marginTop: "20px",
                padding: "20px",
                display: "flex",
                alignItems: "center",
                fontSize: "large",
              }}
              type="button"
              onClick={onOptimize}
            >
              Optimize et
            </button>

            <button
              style={{
                alignSelf: "flex-end",
                backgroundColor: "#830000",
                borderColor: "#830000",
                color: "white",
                height: "30px",
                borderRadius: "5px",
                marginBottom: "5px",
                marginTop: "50px",
                padding: "10px",
                display: "flex",
                alignItems: "center",
              }}
              type="button"
              onClick={onDeleteVehicle}
            >
              Bu aracı sil
            </button>
          </div>
        </div>

        <div style={{ width: "75%" }}>
          <div className="map-container">
            <GoogleMapReact
              bootstrapURLKeys={{
                key: "AIzaSyAkGOMUyxOH98l6qetwEChfJDgzMrBiDbc",
                language: "tr",
                region: "tr",
                libraries: ["drawing"],
              }}
              yesIWantToUseGoogleMapApiInternals //this is important!
              onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
              options={function (maps) {
                return {
                  mapTypeControl: true,
                  mapTypeId: "roadmap",
                  mapTypeControlOptions: {
                    mapTypeIds: ["roadmap", "satellite"],
                    style: maps.MapTypeControlStyle.HORIZONTAL_BAR,
                    position: maps.ControlPosition.TOP_LEFT,
                  },
                };
              }}
              //   array.reduce((sum, obj) => sum + obj[field], 0) / array.length;
              //  defaultCenter={people[0] ? { lat: (people.reduce((sum,obj) => sum+obj.lat,0) / people.length), lng: (people.reduce((sum,obj) => sum+obj.lng,0) / people.length) } : { lat: 36.8969, lng: 30.7133 }}
              center={
                people.length
                  ? {
                      lat: (people.reduce((sum, obj) => sum + obj["lat"], 0) + AIRPORT_LAT) / (people.length + 1),
                      lng: (people.reduce((sum, obj) => sum + obj["lng"], 0) + AIRPORT_LNG) / (people.length + 1),
                    }
                  : { lat: AIRPORT_LAT, lng: AIRPORT_LNG }
              }
              defaultZoom={12}
            >
              {calculatedPeople.map((person, index) => {
                return (
                  <Marker
                    Icon={RoomIcon}
                    lat={person.lat}
                    lng={person.lng}
                    key={person.id}
                    info={index + 1 + ". " + person.name + " - " + person?.polylineLabel}
                    initialShow={true}
                  />
                );
              })}
              <Marker
                Icon={AirportIcon}
                lat={AIRPORT_LAT}
                lng={AIRPORT_LNG}
                key={AIRPORT_ID}
                info={"Havaalanı - " + airportTime}
                alwaysShowMarker={true}
              />
            </GoogleMapReact>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Vehicle;
