import React, { useContext, useEffect } from "react";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import Card from "shared/components/UIElements/Card";
import GenericError from "shared/components/UIElements/GenericError";
import Input from "shared/components/FormElements/Input";
import Button from "shared/components/FormElements/Button";
import Link from "shared/components/FormElements/Link";
import { VALIDATOR_MINLENGTH, VALIDATOR_REQUIRE } from "shared/util/validators";
import { useForm } from "shared/hooks/form-hook";
import { useHttpClient } from "shared/hooks/http-hook";
import { AuthContext } from "shared/context/auth-context";
import "./Auth.css";

const Auth = () => {
  const auth = useContext(AuthContext);
  const { t } = useTranslation();
  const history = useHistory();

  const [genericError, setGenericError] = React.useState(null);

  const { isLoading, sendRequest } = useHttpClient();
  const [loginFailed, setLoginFailed] = React.useState(false);
  const [failedMessage1, setFailedMessage1] = React.useState("Error");
  const [failedMessage2, setFailedMessage2] = React.useState("");

  const [formState, inputHandler] = useForm(
    {
      phonenumber: {
        value: "",
        isValid: false,
      },
      password: {
        value: "",
        isValid: false,
      },
    },
    false
  );

  useEffect(() => {
    if (localStorage.getItem("GenericError")) {
      setGenericError(localStorage.getItem("GenericError"));
      localStorage.removeItem("GenericError");
    }
  }, [auth]);

  const onHandleInput = (event) => {
    setLoginFailed(false);
  };

  const authSubmitHandler = async (event) => {
    event.preventDefault();

    let language = (i18next?.language || window?.localStorage?.i18nextLng || "en").substring(0, 2);
    try {
      const responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/users/login`,
        "POST",
        JSON.stringify({
          phonenumber: formState.inputs.phonenumber.value,
          password: formState.inputs.password.value,
          language: language,
        }),
        {
          "Content-Type": "application/json",
        }
      );
      auth.login(responseData.userId, responseData.token, null, responseData.userName);
      history.replace("/users");
    } catch (err) {
      const firstDotIndex = err?.message?.indexOf(".");
      if (firstDotIndex !== -1 && err.code === 403) {
        setFailedMessage1(err?.message?.slice(0, firstDotIndex + 1));
        setFailedMessage2(err?.message?.slice(firstDotIndex + 1).trim());
      } else {
        setFailedMessage1(err?.message);
        setFailedMessage2("");
      }
      document.getElementById("phonenumber").focus();
      setLoginFailed(true);
    }
  };

  return (
    <React.Fragment>
      {genericError && <GenericError text={genericError} />}
      <Card className="authentication">
        <h2>{t("userLogin")}</h2>
        <hr />
        <form onSubmit={authSubmitHandler}>
          <Input
            element="input"
            id="phonenumber"
            type="phonenumber"
            label={t("emailOrPhone")}
            validators={[VALIDATOR_REQUIRE]}
            onInput={inputHandler}
            onChange={onHandleInput}
            disabled={isLoading}
            autoComplete={"username"}
          />
          <Input
            element="input"
            id="password"
            type="password"
            label={t("password")}
            validators={[VALIDATOR_MINLENGTH(6)]}
            //errorText="En az 6 karakterden oluşan şifrenizi giriniz."
            onInput={inputHandler}
            onChange={onHandleInput}
            disabled={isLoading}
            autoComplete={"current-password"}
          />
          <Button type="submit" isLoading={isLoading} disabled={!formState.isValid || isLoading}>
            {t("login")}
          </Button>

          <div className="auth-bottom-container">
            <div className="auth-messages-container">
              {loginFailed && (
                <div className="login-failed">
                  <p>{failedMessage1}</p>
                  <p>{failedMessage2}</p>
                </div>
              )}
            </div>
            <div className="auth-links-container">
              <Link to="/sign-up" styles={{ float: "left", fontWeight: "bold" }}>
                {t("signup")}
              </Link>
              <Link to="/reset-password" styles={{ float: "right", fontWeight: "bold" }}>
                {t("resetPassword")}
              </Link>
            </div>
          </div>
        </form>
      </Card>
    </React.Fragment>
  );
};

export default Auth;
