import React, { useEffect, useState, useContext, useRef, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import GoogleMapReact from "google-map-react";
import RoomIcon from "@mui/icons-material/Room";
import SchoolIcon from "@mui/icons-material/School";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

// #region Components
import Input from "shared/components/FormElements/Input";
import Button from "shared/components/FormElements/Button";
import CustomSelect from "shared/components/FormElements/Select";
import LoadingSpinner from "shared/components/UIElements/LoadingSpinner";
import ErrorModal from "shared/components/UIElements/ErrorModal";
import Modal from "shared/components/UIElements/Modal";
import NotFound from "shared/components/UIElements/NotFound";
import Marker from "shared/components/UIElements/Marker";
import DatePicker from "shared/components/FormElements/DatePicker";
// #endregion Components

// #region Utils
import { GetStartStopTime, CheckTimeString } from "shared/util/time";
import { personelServiceRouteTypes } from "shared/util/types";
import { VALIDATOR_REQUIRE } from "shared/util/validators";
import { parseComplexDate } from "shared/util/util";
// #endregion Utils

// #region Hooks
import { useForm } from "shared/hooks/form-hook";
import { useHttpClient } from "shared/hooks/http-hook";
import { AuthContext } from "shared/context/auth-context";
import { StoreContext } from "shared/context/store-context";
import { isArray } from "lodash";
// #endregion Hooks

const PersonelServiceItem = (props) => {
  const { t } = useTranslation();
  const auth = useContext(AuthContext);
  const {
    schools,
    users,
    schoolOptions,
    UpdatePersonelService,
    DeletePersonelService,
    driverOptions,
    userOptions,
    shiftOptions,
    stops,
    storeReady,
    shiftUsers,
    shiftUserOptions,
  } = useContext(StoreContext);

  const history = useHistory();
  const [loadedItem, setLoadedItem] = useState();
  const [showAlert, setShowAlert] = useState(false);
  const [currentStops, setCurrentStops] = useState([]);
  const [itemstate, setItemState] = useState();
  const alertMessageRef = useRef("");

  const itemId = props.itemid;
  const ItemType = "personelservice";
  const ItemEndPointType = "personelservices";

  // #region Standard Interface functions
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const showDeleteWarningHandler = () => {
    setShowConfirmModal(true);
  };
  const cancelDeleteHandler = () => {
    setShowConfirmModal(false);
  };

  const updateCurrentStops = (data) => {
    if (!data) {
      setCurrentStops([]);
      return;
    }

    const nowUsers = shiftUsers.filter((item) =>
      isArray(data) ? data.includes(item.id) : formState.inputs.users.value.includes(item.id)
    );
    const newStops = [];

    for (let i = 0; i < nowUsers.length; i++) {
      const user = nowUsers[i];
      const nowStop =
        formState.inputs.type === "100"
          ? stops.find((stop) => stop.id === user?.getonstop)
          : stops.find((stop) => stop.id === user?.getoffstop);
      if (newStops.findIndex((stop) => stop.id === nowStop.id) === -1) {
        newStops.push({ id: nowStop.id, name: nowStop.name, location: nowStop.location, users: [user] });
      } else {
        newStops.find((stop) => stop.id === nowStop.id).users.push(user);
      }
    }
    setCurrentStops(newStops);
  };

  const fetchItem = useCallback(async () => {
    try {
      const responseData = await sendRequest(`${process.env.REACT_APP_BACKEND_URL}/${ItemEndPointType}/${itemId}`);
      setLoadedItem(responseData[ItemType]);
      updateCurrentStops(responseData[ItemType].users);
      setItemState(Date.now());
    } catch (err) {
      if (err?.code === 401) {
        auth.logout();
        history.replace("/login");
      }
    }
  }, [itemId]);

  const confirmDeleteHandler = async () => {
    setShowConfirmModal(false);
    try {
      const deleteResult = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/${ItemEndPointType}/${itemId}`,
        "DELETE",
        null,
        {
          "Content-Type": "application/json",
        }
      );

      if (deleteResult?.message === "Deleted") {
        DeletePersonelService(itemId);
        props.onClose();
      }
    } catch (err) {}
  };
  // #endregion Standard API calls

  useEffect(() => {
    fetchItem();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemId]);

  const [formState, inputHandler] = useForm(
    {
      school: { value: null, isValid: false },
      type: { value: null, isValid: false },
      name: { value: null, isValid: false },
      drivers: { value: [], isValid: false },
      date: { value: null, isValid: true },
      plate: { value: null, isValid: true },
      starttime: { value: null, isValid: false },
      stoptime: { value: null, isValid: false },
      users: { value: [], isValid: false },
      shift: { value: null, isValid: true },
    },
    true
  );

  const itemUpdateSubmitHandler = async (event) => {
    event.preventDefault();

    if (!CheckTimeString(formState.inputs.starttime.value) || !CheckTimeString(formState.inputs.stoptime.value)) {
      alertMessageRef.current = "Saat bilgisini **:** formatında giriniz. Örnek: 07:00 ya da 18:35";
      setShowAlert(true);
      return;
    }

    try {
      const infoToBeSent = {
        school: formState.inputs.school.value,
        type: formState.inputs.type.value,
        name: formState.inputs.name.value,
        drivers: formState.inputs.drivers.value,
        plate: formState.inputs.plate.value,
        date: loadedItem.date,
        starttime: formState.inputs.starttime.value,
        stoptime: formState.inputs.stoptime.value,
        isshift: false,
        shift: formState.inputs.shift.value,
        users: formState.inputs.users.value,
      };

      const responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/${ItemEndPointType}/${itemId}`,
        "PATCH",
        JSON.stringify(infoToBeSent),
        {
          "Content-Type": "application/json",
          Authorization: "Bearer " + auth.token,
        }
      );

      if (responseData?.[ItemType]) {
        UpdatePersonelService(responseData[ItemType]);
        setLoadedItem({ ...responseData[ItemType] });
        setItemState(Date.now());
      }
    } catch (err) {}
  };

  if (isLoading || !storeReady) {
    return (
      <div className="center">
        <LoadingSpinner />
      </div>
    );
  }

  if (!loadedItem && !error) {
    return <NotFound item={ItemType}></NotFound>;
  }

  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setShowAlert(false);
  };

  return (
    <React.Fragment>
      <ErrorModal error={error} onClear={clearError} />
      <Modal
        show={showConfirmModal}
        onCancel={cancelDeleteHandler}
        header={t("areYouSure")}
        footerClass="place-item__modal-actions"
        footer={
          <div className="modalbuttonscontainer">
            <Button inverse onClick={cancelDeleteHandler}>
              {t("no")}
            </Button>
            <Button danger onClick={confirmDeleteHandler}>
              {t("yes")}
            </Button>
          </div>
        }
      >
        <p>{t(`shift${ItemType}.deleteItemWarning`)}</p>
      </Modal>

      <Snackbar
        open={showAlert}
        autoHideDuration={2000}
        onClose={handleAlertClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <MuiAlert onClose={handleAlertClose} severity="error" sx={{ width: "100%" }}>
          {alertMessageRef.current}
        </MuiAlert>
      </Snackbar>

      {!isLoading && loadedItem && (
        <form key={itemstate} className="item-form" onSubmit={itemUpdateSubmitHandler}>
          <div className="button-container">
            <Button type="button" size="small" danger onClick={props.onClose}>
              &#60; {t("close")}
            </Button>
          </div>

          <CustomSelect
            options={schoolOptions}
            id="school"
            onInput={inputHandler}
            label={t("client")}
            initialValue={schoolOptions.find((option) => option.value === loadedItem.school.toString())}
            initialValid={true}
          ></CustomSelect>

          <DatePicker
            id="date"
            onInput={inputHandler}
            label="Servis Tarihi"
            initialValue={loadedItem.date}
            initialValid={true}
          ></DatePicker>

          <CustomSelect
            options={shiftOptions}
            id="shift"
            onInput={inputHandler}
            initialValue={shiftOptions.find((option) => option.value === loadedItem.shift)}
            initialValid={true}
            label="Vardiya"
          ></CustomSelect>

          <CustomSelect
            options={personelServiceRouteTypes}
            id="type"
            onInput={inputHandler}
            initialValue={personelServiceRouteTypes.find((option) => option.value === loadedItem.type)}
            initialValid={true}
            label="Rota Tipi"
          ></CustomSelect>

          <Input
            id="name"
            element="input"
            type="text"
            label={t("name")}
            validators={[VALIDATOR_REQUIRE()]}
            errorText={t("requireField", { item: t("name") })}
            initialValue={loadedItem.name}
            initialValid={true}
            onInput={inputHandler}
          />

          <CustomSelect
            options={driverOptions}
            id="drivers"
            isMulti={true}
            onInput={inputHandler}
            label="Rotaya Yetkisi Olan Sürücüler"
            initialValue={driverOptions.filter((option) => loadedItem?.drivers?.includes(option.value))}
            initialValid={true}
          ></CustomSelect>

          <Input
            id="plate"
            element="input"
            type="text"
            label="Güzergaha tanımlı aracın plakası"
            validators={[VALIDATOR_REQUIRE()]}
            errorText="Zorunlu alan"
            initialValue={loadedItem.plate}
            initialValid={true}
            onInput={inputHandler}
          />

          <Input
            id="starttime"
            element="input"
            type="text"
            label="Başlama Saati"
            validators={[VALIDATOR_REQUIRE()]}
            errorText="**:** formatında giriniz. Örnek: 07:00 ya da 18:35"
            initialValue={GetStartStopTime(loadedItem.starttime)}
            initialValid={true}
            onInput={inputHandler}
          />
          <Input
            id="stoptime"
            element="input"
            type="text"
            label="Bitiş Saati"
            validators={[VALIDATOR_REQUIRE()]}
            errorText="**:** formatında giriniz. Örnek: 07:00 ya da 18:35"
            initialValue={GetStartStopTime(loadedItem.stoptime)}
            initialValid={true}
            onInput={inputHandler}
          />

          <CustomSelect
            options={shiftUserOptions}
            id="users"
            isMulti={true}
            onInput={inputHandler}
            label="Servisteki Kullanıcılar (Dağıtma servislerinde kart okutma ile personel binince kendini ekleyebilir)"
            initialValue={shiftUserOptions.filter((option) => loadedItem?.users?.includes(option.value))}
            initialValid={true}
          ></CustomSelect>

          {formState?.inputs?.users?.value?.length > 0 && (
            <React.Fragment>
              <Button type="button" danger o onClick={updateCurrentStops}>
                Haritayı Güncelle
              </Button>

              <div className="map-container">
                <GoogleMapReact
                  bootstrapURLKeys={{
                    key: "AIzaSyAkGOMUyxOH98l6qetwEChfJDgzMrBiDbc",
                    language: "tr",
                    region: "tr",
                    libraries: ["drawing"],
                  }}
                  options={function (maps) {
                    return {
                      mapTypeControl: true,
                      mapTypeId: "satellite",
                      mapTypeControlOptions: {
                        mapTypeIds: ["satellite", "roadmap"],
                        style: maps.MapTypeControlStyle.HORIZONTAL_BAR,
                        position: maps.ControlPosition.TOP_LEFT,
                      },
                    };
                  }}
                  //defaultCenter={markerLocationMorning}
                  center={currentStops.length ? currentStops[0]?.location : { lat: 36.8969, lng: 30.7133 }}
                  defaultZoom={14}
                >
                  {formState?.inputs?.school?.value && (
                    <Marker
                      Icon={SchoolIcon}
                      lat={schools.find((school) => formState.inputs.school.value === school.id).location.lat}
                      lng={schools.find((school) => formState.inputs.school.value === school.id).location.lng}
                      info={schools.find((school) => formState.inputs.school.value === school.id).name}
                    />
                  )}

                  {currentStops.map((stop) => {
                    return (
                      <Marker
                        Icon={RoomIcon}
                        lat={stop?.location?.lat || 1.1}
                        lng={stop?.location?.lng || 1.1}
                        key={stop.id}
                        info={`${stop.name} - ${stop.users.map((user) => user.name)}`}
                      />
                    );
                  })}
                </GoogleMapReact>
              </div>
            </React.Fragment>
          )}

          <div className="button-container">
            <Button type="submit" disabled={!formState.isValid}>
              {t("update")}
            </Button>

            <Button type="button" danger onClick={showDeleteWarningHandler}>
              {t("delete")}
            </Button>
          </div>
        </form>
      )}
    </React.Fragment>
  );
};

export default PersonelServiceItem;
