import React, { useEffect, useState, useContext, useCallback, useRef } from "react";
import { useHistory } from "react-router-dom";
import GoogleMapReact from "google-map-react";
import RoomIcon from "@mui/icons-material/Room";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

// #region Components
import Input from "shared/components/FormElements/Input";
import Button from "shared/components/FormElements/Button";
import CustomSelect from "shared/components/FormElements/Select";
import Card from "shared/components/UIElements/Card";
import LoadingSpinner from "shared/components/UIElements/LoadingSpinner";
import ErrorModal from "shared/components/UIElements/ErrorModal";
import Modal from "shared/components/UIElements/Modal";
import Marker from "shared/components/UIElements/Marker";
// #endregion Components

// #region Utils
import { vehicleroutetypes } from "shared/util/types";
import { VALIDATOR_REQUIRE } from "shared/util/validators";

import { GetStartStopTime } from "shared/util/time";
import { formatDate, CheckTimeString } from "shared/util/time";
// #endregion Utils

// #region Hooks
import { useForm } from "shared/hooks/form-hook";
import { useHttpClient } from "shared/hooks/http-hook";
import { AuthContext } from "shared/context/auth-context";
import { StoreContext } from "shared/context/store-context";
// #endregion Hooks

const VehicleRouteItem = (props) => {
  const auth = useContext(AuthContext);
  const store = useContext(StoreContext);
  const [drivers, setDrivers] = useState([]);

  const [currentStudents, setCurrentStudents] = useState();
  const [view, setView] = useState(1);
  const [currentSmses, setCurrentSmses] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const alertMessageRef = useRef("");

  const [itemstate, setItemState] = useState();
  const [loadedItem, setLoadedItem] = useState();

  const itemId = props.itemid;
  const updateStoreItems = store.UpdateVehicleRoutes;

  const ItemTypeTR = "Servis";
  const ItemType = "vehicleRoute";
  const ItemEndPointType = "vehicleroutes";

  // #region Standard Interface functions
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const history = useHistory();

  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const showDeleteWarningHandler = () => {
    setShowConfirmModal(true);
  };

  const cancelDeleteHandler = () => {
    setShowConfirmModal(false);
  };
  // #endregion Standard Interface functions

  const [formState, inputHandler] = useForm(
    {
      school: { value: "", isValid: true },
      type: { value: "", isValid: true },
      name: { value: null, isValid: true },
      driver: { value: [], isValid: true },
      plate: { value: "", isValid: true },
      starttime: { value: null, isValid: true },
      stoptime: { value: null, isValid: true },
      student: { value: [], isValid: true },
    },
    true
  );

  // #region Standard API calls
  const fetchItem = useCallback(async () => {
    try {
      const responseData = await sendRequest(`${process.env.REACT_APP_BACKEND_URL}/${ItemEndPointType}/${itemId}`);
      setLoadedItem(responseData[ItemType]);

      setDrivers(
        store.users
          .filter((item) => responseData[ItemType].driver.includes(item.id))
          .map((item) => {
            return {
              id: item.id,
              name: item.name,
              phonenumber: item.phonenumber,
            };
          })
      );

      setCurrentStudents(
        store.students
          .filter((item) => responseData[ItemType].student.includes(item.id))
          .map((item) => {
            return {
              id: item.id,
              name: item.name,
              location: responseData[ItemType].type === 100 ? item.locationgeton : item.locationgetoff,
              identifier: item.identifier,
              phonenumber: item.phonenumber,
            };
          })
      );

      loadSMS();

      setItemState(Date.now());
    } catch (err) {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sendRequest, itemId]);

  const loadSMS = useCallback(async () => {
    try {
      const responseData = await sendRequest(`${process.env.REACT_APP_BACKEND_URL}/xrytutdvsdfds/${itemId}`, "POST");
      setCurrentSmses(responseData.smses);
    } catch (err) {}
  }, [sendRequest, itemId]);

  const confirmDeleteHandler = async () => {
    setShowConfirmModal(false);
    try {
      await sendRequest(`${process.env.REACT_APP_BACKEND_URL}/${ItemEndPointType}/${itemId}`, "DELETE", null, {
        "Content-Type": "application/json",
      });
      setItemState(Date.now());
      updateStoreItems();
      setLoadedItem(null);
      history.push(`/${ItemEndPointType}`);
    } catch (err) {}
  };
  // #endregion Standard API calls

  useEffect(() => {}, [formState.inputs.student]);

  useEffect(() => {
    fetchItem();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemId]);

  // eslint-disable-next-line
  const itemUpdateSubmitHandler = useCallback(async (event) => {
    event.preventDefault();
    let responseData;

    if (!CheckTimeString(formState.inputs.starttime.value) || !CheckTimeString(formState.inputs.stoptime.value)) {
      alertMessageRef.current = "Saat bilgisini **:** formatında giriniz. Örnek: 07:00 ya da 18:35";
      setShowAlert(true);
      return;
    }

    try {
      responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/${ItemEndPointType}/${itemId}`,
        "PATCH",
        JSON.stringify({
          name: formState.inputs.name.value,
          type: formState.inputs.type.value,
          school: formState.inputs.school.value,
          driver: formState.inputs.driver.value,
          plate: formState.inputs.plate.value,
          starttime: formState.inputs.starttime.value,
          stoptime: formState.inputs.stoptime.value,
          student: formState.inputs.student.value,
          status: 1,
          location: { lat: -1, lng: -1 },
          routelocations: [],
        }),
        {
          "Content-Type": "application/json",
          Authorization: "Bearer " + auth.token,
        }
      );

      setLoadedItem(responseData[ItemType]);
      setItemState(Date.now());
      updateStoreItems();

      history.push(`/${ItemEndPointType}`);
    } catch (err) {}
  });

  const updateCurrentStudents = () => {
    setCurrentStudents(
      store.students
        .filter((item) => formState.inputs.student.value.includes(item.id))
        .map((item) => {
          return {
            id: item.id,
            name: item.name,
            location: loadedItem.type === 100 ? item.locationgeton : item.locationgetoff,
            identifier: item.identifier,
            phonenumber: item.phonenumber,
          };
        })
    );
  };

  const GotoView = (view) => {
    setView(view);
  };

  const getPhoneNumberofStudent = (studentId) => {
    try {
      const foundRelation = store.relations.find((relation) => relation.student.toString() === studentId.toString());
      if (foundRelation) {
        return store.users.find((user) => user.id.toString() === foundRelation.parent.toString()).phonenumber;
      } else {
        return "";
      }
    } catch (err) {
      return "";
    }
  };

  const SendSMS = async (studentId) => {
    const message = document.getElementById("text" + studentId).value.trim();

    const foundRelation = store.relations.find((relation) => relation.student.toString() === studentId.toString());

    if (!foundRelation) {
      alert("Kişi için bildirim tanımlanmamış.");
      return;
    }

    const foundPhone = store.users.find((user) => user.id.toString() === foundRelation.parent.toString()).phonenumber;

    const user = foundRelation.parent.toString();
    const student = studentId.toString();
    const vehicleRoute = itemId.toString();
    const phone = foundPhone;
    const text = message;
    const date = formatDate(new Date());
    const status = 1;

    const smsData = { user, student, phone, text, date, status, vehicleRoute };

    try {
      await sendRequest(`${process.env.REACT_APP_BACKEND_URL}/xrytutdvsdfds`, "POST", JSON.stringify(smsData), {
        "Content-Type": "application/json",
        Authorization: "Bearer " + auth.token,
      });
    } catch (err) {}

    await loadSMS();
  };

  const SendSMSToDriver = async (driverId) => {
    const message = document.getElementById("text" + driverId).value.trim();

    const foundPhone = drivers.find((driver) => driver.id === driverId).phonenumber;

    const user = driverId.toString();
    const student = "619c64be5d123200169418c8";
    const vehicleRoute = itemId.toString();
    const phone = foundPhone;
    const text = message;
    const date = formatDate(new Date());
    const status = 1;

    const smsData = { user, student, phone, text, date, status, vehicleRoute };

    try {
      await sendRequest(`${process.env.REACT_APP_BACKEND_URL}/xrytutdvsdfds`, "POST", JSON.stringify(smsData), {
        "Content-Type": "application/json",
        Authorization: "Bearer " + auth.token,
      });
    } catch (err) {}

    await loadSMS();
  };

  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setShowAlert(false);
  };

  if (isLoading) {
    return (
      <div className="center">
        <LoadingSpinner />
      </div>
    );
  }

  if (!loadedItem && !error) {
    return (
      <div className="center">
        <Card>
          <h2>{ItemTypeTR} bulunamadı.</h2>
        </Card>
      </div>
    );
  }

  return (
    <React.Fragment>
      <ErrorModal error={error} onClear={clearError} />
      <Modal
        show={showConfirmModal}
        onCancel={cancelDeleteHandler}
        header="Emin misiniz?"
        footerClass="place-item__modal-actions"
        footer={
          <React.Fragment>
            <Button inverse onClick={cancelDeleteHandler}>
              Hayır
            </Button>
            <Button danger onClick={confirmDeleteHandler}>
              Evet
            </Button>
          </React.Fragment>
        }
      >
        <p>{ItemTypeTR} silme işlemini onaylıyor musunuz? Bu işlem geri alınamaz.</p>
      </Modal>

      <Snackbar
        open={showAlert}
        autoHideDuration={2000}
        onClose={handleAlertClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <MuiAlert onClose={handleAlertClose} severity="error" sx={{ width: "100%" }}>
          {alertMessageRef.current}
        </MuiAlert>
      </Snackbar>

      {!isLoading && loadedItem && currentStudents && (
        <form key={itemstate} className="item-form" onSubmit={itemUpdateSubmitHandler}>
          <div className="button-container">
            <Button type="button" danger onClick={props.onClose}>
              &#60; Geri
            </Button>
            {view === 1 && (
              <Button type="button" danger onClick={() => GotoView(2)}>
                SMS Yönetimi &#62;
              </Button>
            )}

            {view === 2 && (
              <Button type="button" danger onClick={() => GotoView(1)}>
                Servis Yönetimi &#62;
              </Button>
            )}
          </div>

          {view === 1 && (
            <div>
              <CustomSelect
                options={store.schoolOptions}
                id="school"
                onInput={inputHandler}
                label="Okul"
                initialValue={store.schoolOptions.find((option) => option.value === loadedItem.school)}
                initialValid={true}
              ></CustomSelect>
              <Input
                id="name"
                element="input"
                type="text"
                label="İsim"
                validators={[VALIDATOR_REQUIRE()]}
                errorText="İsim giriniz."
                initialValue={loadedItem.name}
                initialValid={true}
                onInput={inputHandler}
              />
              <CustomSelect
                options={vehicleroutetypes}
                id="type"
                onInput={inputHandler}
                label="Rota Tipi"
                initialValue={vehicleroutetypes.find(
                  (option) => option.value.toString() === loadedItem.type.toString()
                )}
                initialValid={true}
              ></CustomSelect>

              <CustomSelect
                options={store.driverOptions}
                id="driver"
                isMulti={true}
                onInput={inputHandler}
                label="Rotaya Yetkisi Olan Sürücüler"
                initialValue={store.driverOptions.filter((option) => loadedItem.driver.includes(option.value))}
                initialValid={true}
              ></CustomSelect>

              <Input
                id="plate"
                element="input"
                type="text"
                label="Güzergaha tanımlı aracın plakası"
                validators={[VALIDATOR_REQUIRE()]}
                errorText="Zorunlu alan"
                onInput={inputHandler}
                initialValue={loadedItem.plate}
                initialValid={true}
              />

              <Input
                id="starttime"
                element="input"
                type="text"
                label="Başlama Saati"
                validators={[VALIDATOR_REQUIRE()]}
                errorText="**:** formatında giriniz. Örnek: 07:00 ya da 18:35"
                onInput={inputHandler}
                initialValue={GetStartStopTime(loadedItem.starttime)}
                initialValid={true}
              />
              <Input
                id="stoptime"
                element="input"
                type="text"
                label="Bitiş Saati"
                validators={[VALIDATOR_REQUIRE()]}
                errorText="**:** formatında giriniz. Örnek: 07:00 ya da 18:35"
                onInput={inputHandler}
                initialValue={GetStartStopTime(loadedItem.stoptime)}
                initialValid={true}
              />

              <CustomSelect
                options={store.studentOptions}
                id="student"
                isMulti={true}
                onInput={inputHandler}
                label="Rotadaki Kişiler"
                initialValue={store.studentOptions.filter((option) => loadedItem.student.includes(option.value))}
                initialValid={true}
              ></CustomSelect>

              <Button type="button" danger o onClick={updateCurrentStudents}>
                Haritayı Güncelle
              </Button>

              <div className="map-container">
                <GoogleMapReact
                  bootstrapURLKeys={{
                    key: "AIzaSyAkGOMUyxOH98l6qetwEChfJDgzMrBiDbc",
                    language: "tr",
                    region: "tr",
                    libraries: ["drawing"],
                  }}
                  options={function (maps) {
                    return {
                      mapTypeControl: true,
                      mapTypeId: "satellite",
                      mapTypeControlOptions: {
                        mapTypeIds: ["satellite", "roadmap"],
                        style: maps.MapTypeControlStyle.HORIZONTAL_BAR,
                        position: maps.ControlPosition.TOP_LEFT,
                      },
                    };
                  }}
                  //defaultCenter={markerLocationMorning}
                  center={currentStudents[0] ? currentStudents[0].location : { lat: 36.8969, lng: 30.7133 }}
                  defaultZoom={16}
                >
                  {currentStudents.map((student) => {
                    return (
                      <Marker
                        Icon={RoomIcon}
                        lat={student.location.lat}
                        lng={student.location.lng}
                        key={student.id}
                        info={student.name}
                      />
                    );
                  })}
                </GoogleMapReact>
              </div>

              <div className="button-container">
                <Button type="submit" disabled={!formState.isValid}>
                  Güncelle
                </Button>

                <Button type="button" danger onClick={showDeleteWarningHandler}>
                  SİL
                </Button>
              </div>
            </div>
          )}

          {view === 2 && (
            <div>
              <TableContainer component={Paper}>
                <Table
                  sx={{ width: "100%", justifyContent: "center", margin: "auto" }}
                  stickyHeader
                  aria-label="simple table"
                >
                  <TableHead>
                    <TableRow>
                      {/* <TableCell></TableCell> */}
                      <TableCell width="10%">
                        <b>Kullanıcı</b>
                      </TableCell>
                      <TableCell width="45%">
                        <b>Giden Mesajlar</b>
                      </TableCell>
                      <TableCell width="45%" align="right">
                        <b>Yeni Mesaj (HER MESAJ EN FAZLA 155 KARAKTER)</b>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {drivers.map((driver) => (
                      <TableRow key={driver.id} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                        <TableCell component="th" scope="row">
                          <b>Sürücü</b>
                          <br /> {driver.name} <br /> {driver.phonenumber}
                        </TableCell>

                        <TableCell component="th" scope="row">
                          {currentSmses.filter((sms) => sms.user.id.toString() === driver.id.toString()).length > 0 ? (
                            <div>
                              {currentSmses
                                .filter((sms) => sms.user.id.toString() === driver.id.toString())
                                .map((sms) => (
                                  <div key={sms.id}>
                                    {sms.text}
                                    <br />
                                    <i>{sms.date}</i>
                                    <br />
                                    <br />
                                  </div>
                                ))}
                            </div>
                          ) : (
                            <div>Mesaj Yok</div>
                          )}
                        </TableCell>

                        <TableCell scope="row" align="right">
                          <div style={{ display: "flex", flexDirection: "column" }}>
                            <textarea
                              style={{ margin: "16px" }}
                              key={"SMS" + driver.id}
                              id={"text" + driver.id.toString().trim()}
                              cols="30"
                              rows="7"
                              maxLength="155"
                            ></textarea>
                            <div width="60px">
                              <Button type="button" danger onClick={() => SendSMSToDriver(driver.id.toString().trim())}>
                                SMS Gönder
                              </Button>
                            </div>
                          </div>
                        </TableCell>
                      </TableRow>
                    ))}

                    {currentStudents.map((student) => (
                      <TableRow key={student.id} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                        {/* <TableCell width="30px" component="th" scope="row">
                      <Button type="button" danger onClick={() => RemoveStudent(row.studentId)}>
                        SMS Gönder
                      </Button>
                    </TableCell> */}

                        <TableCell component="th" scope="row">
                          {student.name} <br /> {getPhoneNumberofStudent(student.id)}
                        </TableCell>

                        <TableCell component="th" scope="row">
                          {currentSmses.filter((sms) => sms.student.id.toString() === student.id.toString()).length >
                          0 ? (
                            <div>
                              {currentSmses
                                .filter((sms) => sms.student.id.toString() === student.id.toString())
                                .map((sms) => (
                                  <div key={sms.id}>
                                    {sms.text}
                                    <br />
                                    <i>{sms.date}</i>
                                    <br />
                                    <br />
                                  </div>
                                ))}
                            </div>
                          ) : (
                            <div>Mesaj Yok</div>
                          )}
                        </TableCell>

                        <TableCell scope="row" align="right">
                          <div style={{ display: "flex", flexDirection: "column" }}>
                            <textarea
                              style={{ margin: "16px" }}
                              key={"SMS" + student.id}
                              id={"text" + student.id.toString().trim()}
                              cols="30"
                              rows="7"
                              maxLength="155"
                            ></textarea>
                            <div width="60px">
                              <Button type="button" danger onClick={() => SendSMS(student.id.toString().trim())}>
                                SMS Gönder
                              </Button>
                            </div>
                          </div>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          )}
        </form>
      )}
    </React.Fragment>
  );
};

export default VehicleRouteItem;
