import React, { useEffect, useState, useContext, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

// #region Components
import Input from "shared/components/FormElements/Input";
import Button from "shared/components/FormElements/Button";
import CustomSelect from "shared/components/FormElements/Select";
import LoadingSpinner from "shared/components/UIElements/LoadingSpinner";
import ErrorModal from "shared/components/UIElements/ErrorModal";
import Modal from "shared/components/UIElements/Modal";
import NotFound from "shared/components/UIElements/NotFound";
// #endregion Components

// #region Utils
import { VALIDATOR_REQUIRE, VALIDATOR_TIME } from "shared/util/validators";
// #endregion Utils

// #region Hooks
import { useForm } from "shared/hooks/form-hook";
import { useHttpClient } from "shared/hooks/http-hook";
import { AuthContext } from "shared/context/auth-context";
import { StoreContext } from "shared/context/store-context";
// #endregion Hooks

import { days, days_en } from "shared/util/types";

const ShiftItem = (props) => {
  const { t } = useTranslation();
  const auth = useContext(AuthContext);
  const history = useHistory();
  const { schoolOptions, UpdateShift, DeleteShift, shiftPersonelServiceOptions, userOptions } =
    useContext(StoreContext);
  const language = (i18next?.language || window?.localStorage?.i18nextLng || "en").substring(0, 2);

  const [itemstate, setItemState] = useState();
  const [loadedItem, setLoadedItem] = useState();

  const itemId = props.itemid;

  const ItemType = "shift";
  const ItemEndPointType = "shifts";

  // #region Standard Interface functions
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const showDeleteWarningHandler = () => {
    setShowConfirmModal(true);
  };
  const cancelDeleteHandler = () => {
    setShowConfirmModal(false);
  };
  // #endregion Standard Interface functions

  // #region Standard API calls
  const fetchItem = useCallback(async () => {
    try {
      const responseData = await sendRequest(`${process.env.REACT_APP_BACKEND_URL}/${ItemEndPointType}/${itemId}`);
      setLoadedItem(responseData[ItemType]);
      setItemState(Date.now());
    } catch (err) {
      if (err?.code === 401) {
        auth.logout();
        history.replace("/login");
      }
    }
    // eslint-disable-next-line
  }, [itemId]);

  const confirmDeleteHandler = async () => {
    setShowConfirmModal(false);
    try {
      const deleteResult = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/${ItemEndPointType}/${itemId}`,
        "DELETE",
        null,
        {
          "Content-Type": "application/json",
        }
      );

      if (deleteResult?.message === "Deleted") {
        DeleteShift(itemId);
        props.onClose();
      }
    } catch (err) {}
  };
  // #endregion Standard API calls

  useEffect(() => {
    fetchItem();
  }, [itemId, fetchItem]);

  const [formState, inputHandler] = useForm(
    {
      name: { value: "", isValid: true },
      day: { value: "", isValid: true },
      starttime: { value: "", isValid: true },
      endtime: { value: "", isValid: true },
      users: { value: [], isValid: true },
      personelservices: { value: [], isValid: true },
      school: { value: "", isValid: true },
    },
    true
  );

  const itemUpdateSubmitHandler = async (event) => {
    event.preventDefault();

    try {
      const responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/${ItemEndPointType}/${itemId}`,
        "PATCH",
        JSON.stringify({
          name: formState.inputs.name.value,
          day: formState.inputs.day.value,
          starttime: formState.inputs.starttime.value,
          endtime: formState.inputs.endtime.value,
          users: formState.inputs.users.value,
          personelservices: formState.inputs.personelservices.value,
          school: formState.inputs.school.value,
        }),
        {
          "Content-Type": "application/json",
          Authorization: "Bearer " + auth.token,
        }
      );

      if (responseData?.[ItemType]) {
        UpdateShift(responseData[ItemType]);
        setLoadedItem(responseData[ItemType]);
        setItemState(Date.now());
      }
    } catch (err) {}
  };

  if (isLoading) {
    return (
      <div className="center">
        <LoadingSpinner />
      </div>
    );
  }

  if (!loadedItem && !error) {
    return <NotFound item={ItemType}></NotFound>;
  }
  return (
    <React.Fragment>
      <ErrorModal error={error} onClear={clearError} />
      <Modal
        show={showConfirmModal}
        onCancel={cancelDeleteHandler}
        header={t("areYouSure")}
        footerClass="place-item__modal-actions"
        footer={
          <React.Fragment>
            <Button inverse onClick={cancelDeleteHandler}>
              {t("no")}
            </Button>
            <Button danger onClick={confirmDeleteHandler}>
              {t("yes")}
            </Button>
          </React.Fragment>
        }
      >
        <p>{t(`${ItemType}.deleteItemWarning`)}</p>
      </Modal>

      {!isLoading && loadedItem && (
        <form key={itemstate} className="item-form" onSubmit={itemUpdateSubmitHandler}>
          <div className="button-container">
            <Button type="button" size="small" danger onClick={props.onClose}>
              &#60; {t("close")}
            </Button>
          </div>
          <CustomSelect
            options={language === "en" ? days_en : days}
            id="day"
            onInput={inputHandler}
            label={t("day")}
            initialValue={days.find((day) => day.value.toString() === loadedItem.day.toString())}
            initialValid={true}
          ></CustomSelect>
          <Input
            id="name"
            element="input"
            type="text"
            label={t("name")}
            validators={[VALIDATOR_REQUIRE()]}
            errorText={t("requireField", { item: t("name") })}
            initialValue={loadedItem.name}
            initialValid={true}
            onInput={inputHandler}
          />

          <Input
            id="starttime"
            element="input"
            type="text"
            label={t("startTime")}
            validators={[VALIDATOR_TIME(formState.inputs.starttime.value)]}
            initialValue={loadedItem.starttime}
            initialValid={true}
            errorText={t("invalidTimeField", { item: t("startTime") })}
            onInput={inputHandler}
          />
          <Input
            id="endtime"
            element="input"
            type="text"
            label={t("endTime")}
            validators={[VALIDATOR_TIME(formState.inputs.endtime.value)]}
            initialValue={loadedItem.endtime}
            initialValid={true}
            errorText={t("invalidTimeField", { item: t("endTime") })}
            onInput={inputHandler}
          />

          <CustomSelect
            options={userOptions}
            id="users"
            isMulti={true}
            onInput={inputHandler}
            label="Vardiyadaki Kişiler"
            initialValue={userOptions.filter((option) => loadedItem?.users?.includes(option.value))}
            initialValid={true}
          ></CustomSelect>

          <CustomSelect
            options={shiftPersonelServiceOptions}
            id="personelservices"
            isMulti={true}
            onInput={inputHandler}
            label="Vardiyaya Ayrılan Servisler"
            initialValue={shiftPersonelServiceOptions.filter((option) =>
              loadedItem?.personelservices?.includes(option.value)
            )}
            initialValid={true}
          ></CustomSelect>

          <CustomSelect
            options={schoolOptions}
            id="school"
            onInput={inputHandler}
            label={t("client")}
            initialValue={schoolOptions.find((option) => option.value === loadedItem.school.toString())}
            initialValid={true}
          ></CustomSelect>

          <div className="button-container">
            <Button type="submit" disabled={!formState.isValid}>
              {t("update")}
            </Button>

            <Button type="button" danger onClick={showDeleteWarningHandler}>
              {t("delete")}
            </Button>
          </div>
        </form>
      )}
    </React.Fragment>
  );
};

export default ShiftItem;
