import React, { useContext } from "react";
import { useHistory } from "react-router-dom";

// #region Components
import Input from "shared/components/FormElements/Input";
import Button from "shared/components/FormElements/Button";
import CustomSelect from "shared/components/FormElements/Select";
import LoadingSpinner from "shared/components/UIElements/LoadingSpinner";
import ErrorModal from "shared/components/UIElements/ErrorModal";
// #endregion Components

// #region Utils
import { VALIDATOR_REQUIRE } from "shared/util/validators";

import { vehicletypes } from "shared/util/types";
// #endregion Utils

// #region Hooks
import { useForm } from "shared/hooks/form-hook";
import { useHttpClient } from "shared/hooks/http-hook";
import { AuthContext } from "shared/context/auth-context";
import { StoreContext } from "shared/context/store-context";
// #endregion Hooks

const NewVehicleItem = (props) => {
  const auth = useContext(AuthContext);
  const store = useContext(StoreContext);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const history = useHistory();

  const updateStoreItems = store.UpdateVehicles;
  const ItemTypeTR = "Araç";
  const ItemEndPointType = "vehicles";
  const APIEndPoint = "vehicles";

  const itemSubmitHandler = async (event) => {
    event.preventDefault();

    try {
      await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/${APIEndPoint}`,
        "POST",
        JSON.stringify({
          plate: formState.inputs.plate.value,
          driver: formState.inputs.driver.value,
          model: formState.inputs.model.value,
          type: formState.inputs.type.value,
          school: formState.inputs.school.value,
          company: formState.inputs.company.value,
        }),
        {
          "Content-Type": "application/json",
          Authorization: "Bearer " + auth.token,
        }
      );

      updateStoreItems();
      history.push(`/${ItemEndPointType}`);
    } catch (err) {}
  };

  const [formState, inputHandler] = useForm(
    {
      plate: { value: "", isValid: false },
      driver: { value: "", isValid: true },
      model: { value: "", isValid: false },
      type: { value: "", isValid: false },
      school: { value: [], isValid: true },
      company: { value: [], isValid: true },
    },
    false
  );

  if (isLoading) {
    return (
      <div className="center">
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <React.Fragment>
      <ErrorModal error={error} onClear={clearError} />
      <form className="new-item-form" onSubmit={itemSubmitHandler}>
        {isLoading && <LoadingSpinner asOverlay />}
        <h3>{ItemTypeTR} Ekle</h3>
        <Input
          id="plate"
          element="input"
          type="text"
          label="Plaka"
          validators={[VALIDATOR_REQUIRE()]}
          errorText="Plaka giriniz."
          onInput={inputHandler}
        />
        <CustomSelect options={store.driverOptions} id="type" onInput={inputHandler} label="Sürücü"></CustomSelect>
        <Input
          id="model"
          element="input"
          type="text"
          label="Model"
          validators={[VALIDATOR_REQUIRE()]}
          errorText="Model giriniz."
          onInput={inputHandler}
        />
        <CustomSelect
          options={vehicletypes}
          id="type"
          onInput={inputHandler}
          label="Türü"
          initialValue={vehicletypes[0]}
        ></CustomSelect>
        <CustomSelect
          isMulti={true}
          options={store.schoolOptions}
          id="school"
          onInput={inputHandler}
          label="Okul"
        ></CustomSelect>
        <CustomSelect
          isMulti={true}
          options={store.companyOptions}
          id="company"
          onInput={inputHandler}
          label="Servis Firması"
        ></CustomSelect>
        <Button type="submit" disabled={!formState.isValid}>
          Yeni {ItemTypeTR} Yarat
        </Button>
      </form>
    </React.Fragment>
  );
};

export default NewVehicleItem;
