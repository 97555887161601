import React, { useContext } from "react";
import { useHistory } from "react-router-dom";

// #region Components
import Input from "shared/components/FormElements/Input";
import Button from "shared/components/FormElements/Button";
import CustomSelect from "shared/components/FormElements/Select";
import LoadingSpinner from "shared/components/UIElements/LoadingSpinner";
import ErrorModal from "shared/components/UIElements/ErrorModal";
// #endregion Components

// #region Utils
import { VALIDATOR_MAX, VALIDATOR_MIN } from "shared/util/validators";
import { notificationtypes, notificationmethods, booleanstatus, durations } from "shared/util/types";

// #endregion Utils

// #region Hooks
import { useForm } from "shared/hooks/form-hook";
import { useHttpClient } from "shared/hooks/http-hook";
import { AuthContext } from "shared/context/auth-context";
import { StoreContext } from "shared/context/store-context";
// #endregion Hooks

const NewRelationItem = (props) => {
  const auth = useContext(AuthContext);
  const store = useContext(StoreContext);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const history = useHistory();

  let studentHeader = "...";

  if (auth.schoolType === "1") {
    studentHeader = "Öğrenci";
  }
  if (auth.schoolType === "2") {
    studentHeader = "Kullanıcı Servisi";
  }

  const updateStoreItems = store.UpdateRelations;
  const ItemTypeTR = "Bildirim";
  const ItemEndPointType = "notifications";
  const APIEndPoint = "relations";

  const itemSubmitHandler = async (event) => {
    event.preventDefault();

    try {
      await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/${APIEndPoint}`,
        "POST",
        JSON.stringify({
          student: formState.inputs.student.value,
          parent: formState.inputs.parent.value,
          notificationtype: formState.inputs.notificationtype.value,
          notificationmethod: formState.inputs.notificationmethod.value,
          isenabled: formState.inputs.isenabled.value,
          approachtogeton: formState.inputs.approachtogeton.value,
          approachtogetoff: formState.inputs.approachtogetoff.value,
          arrivedschool: formState.inputs.arrivedschool.value,
          leavedschool: formState.inputs.leavedschool.value,
          studentgeton: formState.inputs.studentgeton.value,
          studentnotgeton: formState.inputs.studentnotgeton.value,
          distancegeton: formState.inputs.distancegeton.value,
          distancegetoff: formState.inputs.distancegetoff.value,
          durationgeton: formState.inputs.durationgeton.value,
          durationgetoff: formState.inputs.durationgetoff.value,
        }),
        {
          "Content-Type": "application/json",
          Authorization: "Bearer " + auth.token,
        }
      );

      updateStoreItems();
      history.push(`/${ItemEndPointType}`);
    } catch (err) {}
  };

  const [formState, inputHandler] = useForm(
    {
      student: { value: "", isValid: false },
      parent: { value: "", isValid: false },
      notificationtype: { value: [], isValid: false },
      notificationmethod: { value: [], isValid: false },
      isenabled: { value: [], isValid: false },
      approachtogeton: { value: [], isValid: false },
      approachtogetoff: { value: [], isValid: false },
      arrivedschool: { value: [], isValid: false },
      leavedschool: { value: [], isValid: false },
      studentgeton: { value: [], isValid: false },
      studentnotgeton: { value: [], isValid: false },
      distancegeton: { value: [], isValid: false },
      distancegetoff: { value: [], isValid: false },
      durationgeton: { value: [], isValid: false },
      durationgetoff: { value: [], isValid: false },
    },
    false
  );

  if (isLoading) {
    return (
      <div className="center">
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <React.Fragment>
      <ErrorModal error={error} onClear={clearError} />
      <form className="new-item-form" onSubmit={itemSubmitHandler}>
        {isLoading && <LoadingSpinner asOverlay />}
        <h3>{ItemTypeTR} Ekle</h3>
        <CustomSelect
          options={store.parentOptions}
          id="parent"
          onInput={inputHandler}
          label="Bildirim Sahibi"
        ></CustomSelect>

        <CustomSelect
          options={store.studentOptions}
          id="student"
          onInput={inputHandler}
          label={studentHeader}
        ></CustomSelect>

        <CustomSelect
          options={notificationtypes}
          id="notificationtype"
          onInput={inputHandler}
          label="Bildirim Türü"
          initialValue={notificationtypes[0]}
        ></CustomSelect>

        <CustomSelect
          options={notificationmethods}
          id="notificationmethod"
          onInput={inputHandler}
          label="Bildirim Yöntemi"
          initialValue={notificationmethods[0]}
        ></CustomSelect>

        <CustomSelect
          options={booleanstatus}
          id="isenabled"
          onInput={inputHandler}
          label="Genel Bildirim Durumu"
          initialValue={booleanstatus[0]}
        ></CustomSelect>

        <CustomSelect
          options={booleanstatus}
          id="approachtogeton"
          onInput={inputHandler}
          label="Almaya Yaklaşınca"
          initialValue={booleanstatus[1]}
        ></CustomSelect>

        <CustomSelect
          options={booleanstatus}
          id="approachtogetoff"
          onInput={inputHandler}
          label="Bırakmaya Yaklaşınca"
          initialValue={booleanstatus[1]}
        ></CustomSelect>

        <CustomSelect
          options={booleanstatus}
          id="arrivedschool"
          onInput={inputHandler}
          label="Kuruma Varınca"
          initialValue={booleanstatus[1]}
        ></CustomSelect>

        <CustomSelect
          options={booleanstatus}
          id="leavedschool"
          onInput={inputHandler}
          label="Kurumdan Çıkınca"
          initialValue={booleanstatus[1]}
        ></CustomSelect>

        <CustomSelect
          options={booleanstatus}
          id="studentgeton"
          onInput={inputHandler}
          label="Servise Binince"
          initialValue={booleanstatus[0]}
        ></CustomSelect>

        <CustomSelect
          options={booleanstatus}
          id="studentnotgeton"
          onInput={inputHandler}
          label="Servise Binmeyince"
          initialValue={booleanstatus[1]}
        ></CustomSelect>

        <CustomSelect
          options={durations}
          id="durationgeton"
          onInput={inputHandler}
          label="Biniş Öncesi Süre"
          initialValue={durations[4]}
        ></CustomSelect>

        <CustomSelect
          options={durations}
          id="durationgetoff"
          onInput={inputHandler}
          label="İniş Öncesi Süre"
          initialValue={durations[4]}
        ></CustomSelect>

        <Input
          id="distancegeton"
          element="input"
          type="text"
          label="Biniş Öncesi Mesafe"
          validators={[VALIDATOR_MAX(5), VALIDATOR_MIN(0.1)]}
          initialValue={1.9}
          initialValid={true}
          onInput={inputHandler}
          errorText="Mesafe 0.1 ve 5 arasında bir sayı olmalıdır. Km'yi ifade eder."
        />

        <Input
          id="distancegetoff"
          element="input"
          type="text"
          label="İniş Öncesi Mesafe"
          validators={[VALIDATOR_MAX(5), VALIDATOR_MIN(0.1)]}
          initialValue={1.9}
          initialValid={true}
          onInput={inputHandler}
          errorText="Mesafe 0.1 ve 5 arasında bir sayı olmalıdır. Km'yi ifade eder."
        />

        {/* <CustomSelect
          options={distances}
          id="distancegeton"
          onInput={inputHandler}
          label="Biniş Öncesi Mesafe"
          initialValue={durations[4]}
        ></CustomSelect> */}

        {/* <CustomSelect
          options={distances}
          id="distancegetoff"
          onInput={inputHandler}
          label="İniş Öncesi Mesafe"
          initialValue={durations[4]}
        ></CustomSelect> */}

        <Button type="submit" disabled={!formState.isValid}>
          Yeni {ItemTypeTR} Yarat
        </Button>
      </form>
    </React.Fragment>
  );
};

export default NewRelationItem;
