import React, { useContext, useState } from "react";
import {
  DataGrid,
  trTR,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid";
import { vehicleroutetypes } from "shared/util/types";
import { trTR as coretrTR } from "@mui/material/locale";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { StoreContext } from "shared/context/store-context";

const PersonelServicesList = (props) => {
  const store = useContext(StoreContext);

  const visibilityJSON = localStorage.getItem("shiftpersonelservices_visibility");
  const visibilityModel = visibilityJSON ? JSON.parse(visibilityJSON) : {};
  const [columnVisibilityModel, setColumnVisibilityModel] = useState(visibilityModel);
  const filterJSON = localStorage.getItem("shiftpersonelservices_filter");
  const initialFilterModel = filterJSON ? JSON.parse(filterJSON) : { items: [] };
  const [filterModel, setFilterModel] = useState(initialFilterModel);

  const theme = createTheme(
    {
      palette: {
        primary: { main: "#1976d2" },
      },
    },
    trTR,
    coretrTR,
    {
      GridCsvExportOptions: { fileName: "gh" },
    }
  );

  const columns = [
    {
      field: "school",
      headerName: "Kurum",
      width: 200,
      valueGetter: (params) =>
        store.schoolOptions.find((item) => item.value.toString() === params.row.school.toString()).label,
    },
    { field: "date", headerName: "Tarih", width: 150 },
    {
      field: "shift",
      headerName: "Vardiya",
      width: 150,
      valueGetter: (params) => {
        try {
          return store.shiftOptions.find((item) => item.value.toString() === params.row.shift.toString()).label;
        } catch {
          return "";
        }
      },
    },
    { field: "name", headerName: "İsim", width: 300 },
    {
      field: "type",
      headerName: "Türü",
      width: 100,
      valueGetter: (params) =>
        vehicleroutetypes.find((item) => item.value.toString() === params.row.type.toString()).label,
    },
    {
      field: "drivers",
      headerName: "Ana Sürücü",
      width: 300,
      valueGetter: (params) => {
        try {
          return store.driverOptions.find((item) => item.value.toString() === params?.row?.drivers[0]?.toString())
            .label;
        } catch (e) {
          return "";
        }
      },
    },
    { field: "plate", headerName: "Plaka", width: 100 },
  ];

  const OnSelectionChangeHandler = (event) => {
    props.onItemSelect(event[0]);
  };

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarExport csvOptions={{ utf8WithBom: true }} />
      </GridToolbarContainer>
    );
  }

  return (
    <div className="itemListContainer">
      <ThemeProvider theme={theme}>
        <DataGrid
          columnVisibilityModel={columnVisibilityModel}
          onColumnVisibilityModelChange={(newModel) => {
            localStorage.setItem("shiftpersonelservices_visibility", JSON.stringify(newModel));
            setColumnVisibilityModel(newModel);
          }}
          filterModel={filterModel}
          onFilterModelChange={(newModel) => {
            localStorage.setItem("shiftpersonelservices_filter", JSON.stringify(newModel));
            setFilterModel(newModel);
          }}
          key={props.items}
          density="compact"
          components={{
            Toolbar: CustomToolbar,
          }}
          rows={props.items}
          columns={columns}
          pageSize={100}
          getRowId={(r) => r.id}
          onSelectionModelChange={OnSelectionChangeHandler}
        />
      </ThemeProvider>
    </div>
  );
};

export default PersonelServicesList;
