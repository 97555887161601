/* eslint-disable */
import React, { useEffect, useState, useContext, useCallback } from "react";
import { useHistory } from "react-router-dom";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

// #region Components
import Input from "shared/components/FormElements/Input";
import CustomSelect from "shared/components/FormElements/Select";
import DatePicker from "shared/components/FormElements/DatePicker";
import Button from "shared/components/FormElements/Button";
import Card from "shared/components/UIElements/Card";
import LoadingSpinner from "shared/components/UIElements/LoadingSpinner";
import ErrorModal from "shared/components/UIElements/ErrorModal";
import Modal from "shared/components/UIElements/Modal";
import { formatDate } from "shared/util/time";
// #endregion Components

// #region Utils
import { VALIDATOR_REQUIRE, VALIDATOR_PHONENUMBER, VALIDATOR_NONE } from "shared/util/validators";
import { isCompanyReceiptOptions, userApproved } from "shared/util/types";
import { parseComplexDate, CheckAuthorization } from "shared/util/util";
// #endregion Utils

// #region Hooks
import { useForm } from "shared/hooks/form-hook";
import { useHttpClient } from "shared/hooks/http-hook";
import { AuthContext } from "shared/context/auth-context";
import { StoreContext } from "shared/context/store-context";
// #endregion Hooks

import "./AccountingItem.css";

const ContractItem = (props) => {
  const itemId = props.itemid;

  const auth = useContext(AuthContext);
  const store = useContext(StoreContext);
  const Authorization = CheckAuthorization(auth.userId);

  const [openSmsSend, setOpenSmsSend] = useState(false);

  const [itemstate, setItemState] = useState();
  const [loadedItem, setLoadedItem] = useState();

  const [userApprovedStatus, setUserApproved] = useState();
  const [userApprovedKey, setUserApprovedKey] = useState();

  const [serviceStartDate, setServiceStartDate] = useState();
  const [serviceStartDateKey, setServiceStartDateKey] = useState();
  const [serviceEndingDate, setServiceEndingDate] = useState();
  const [serviceEndingDateKey, setServiceEndingDateKey] = useState();
  const [totalAmount, setTotalAmount] = useState();
  const [totalAmountKey, setTotalAmountKey] = useState();
  const [educationYear, setEducationYear] = useState();
  const [educationYearKey, setEducationYearKey] = useState();
  const [isCompanyReceipt, setIsCompanyReceipt] = useState(false);
  const [contractDate, setContractDate] = useState(false);
  const [contractDateKey, setContractDateKey] = useState(false);
  const [finalAmount, setFinalAmount] = useState();
  const [finalAmountKey, setFinalAmountKey] = useState();

  const [phoneNumber, setPhoneNumber] = useState();
  const [parent, setParent] = useState();

  const ItemTypeTR = "Sözleşme";
  const ItemType = "contract";
  const ItemEndPointType = "contracts";
  const APIEndPoint = "contracts";

  const updateStoreItems = store.UpdateContracts;

  // #region Standard Interface functions
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const history = useHistory();

  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showSmsModal, setShowSmsModal] = useState(false);

  const showDeleteWarningHandler = () => {
    setShowConfirmModal(true);
  };

  const cancelDeleteHandler = () => {
    setShowConfirmModal(false);
  };

  const showSMSHandler = () => {
    if (
      formStatePhoneNumber.inputs.phonenumber.value.trim().length === 10 &&
      formStatePhoneNumber.inputs.phonenumber.value.trim().toString().charAt(0) == "5"
    ) {
      setShowSmsModal(true);
    }
  };

  const cancelSMSHandler = () => {
    setShowSmsModal(false);
  };
  // #endregion Standard Interface functions

  // #region Standard API calls
  const fetchItem = useCallback(async () => {
    try {
      const responseData = await sendRequest(`${process.env.REACT_APP_BACKEND_URL}/${ItemEndPointType}/${itemId}`);

      const myuser = store.users.find((user) =>
        store.relations.find((relation) => relation.student.toString() === responseData[ItemType].student.toString())
          ? user.id ==
            store.relations
              .find((relation) => relation.student.toString() === responseData[ItemType].student.toString())
              .parent.toString()
          : null
      );

      if (myuser) {
        setParent(myuser);
      }
      if (myuser?.phonenumber) {
        setPhoneNumber(myuser.phonenumber);
      }

      setLoadedItem(responseData[ItemType]);
      setItemState(Date.now().toString());
    } catch (err) {}
  });

  const confirmDeleteHandler = async () => {
    setShowConfirmModal(false);
    try {
      await sendRequest(`${process.env.REACT_APP_BACKEND_URL}/${ItemEndPointType}/${itemId}`, "DELETE", null, {
        "Content-Type": "application/json",
      });
      setItemState(Date.now());
      updateStoreItems();
      setLoadedItem(null);
      //   history.push(`/${ItemEndPointType}`);
    } catch (err) {}
  };
  // #endregion Standard API calls

  useEffect(() => {
    fetchItem();
  }, [itemId]);

  const [formState, inputHandler, setFormData] = useForm(
    {
      payerName: { value: "", isValid: true },
      payerIdentity: { value: "", isValid: true },
      payerAddress: { value: "", isValid: true },
      payerMail: { value: "", isValid: true },
      student: { value: "", isValid: true },
      year: { value: "", isValid: true },
      date: { value: "", isValid: true },
      contractEnabled: { value: true, isValid: true },
      serviceTemplate: { value: "", isValid: true },
      serviceStartDate: { value: "", isValid: true },
      serviceEndingDate: { value: "", isValid: true },
      totalAmount: { value: "", isValid: true },
      finalAmount: { value: "", isValid: true },
      isCompanyReceipt: { value: isCompanyReceipt[0], isValid: true },
      taxNo: { value: "", isValid: true },
      taxOffice: { value: "", isValid: true },
      companyTitle: { value: "", isValid: true },
      standardDiscounts: { value: [], isValid: true },
      specialDiscount: { value: 0, isValid: true },
      specialDiscountDescription: { value: "", isValid: true },
      userApproved: { value: userApproved[1], isValid: true },
    },
    true
  );

  const [formStatePhoneNumber, inputHandlerPhoneNumber, setFormDataPhoneNumber] = useForm(
    {
      phonenumber: { value: "", isValid: false },
    },
    false
  );

  const checkContractStatus = async (tey) => {
    try {
      const responseData = await sendRequest(`${process.env.REACT_APP_BACKEND_URL}/${ItemEndPointType}/${itemId}`);
      const contractApprovedStatus = responseData[ItemType].userApproved;

      setUserApproved(contractApprovedStatus);
      setUserApprovedKey(contractApprovedStatus.toString() + "-" + Math.random());
      inputHandler("userApproved", contractApprovedStatus, true);
      loadedItem.userApproved = contractApprovedStatus;
    } catch (err) {}

    //loadedItem.contractEnabled ? setFormData({ ...formState, contractEnabled: { value: true, isValid: true } }) : setFormData({ ...formState, contractEnabled: { value: false, isValid: true } });
  };

  const sendContractApprovalLink = useCallback(async () => {
    setShowSmsModal(false);

    let message = `Öğrenci taşıma sözleşmeniz oluşturulmuştur. Lütfen Servistesin uygulaması üzerinden durak bilgilerinizi kontrol ediniz. Çetin Tur`;

    message = `Sözleşme onayınız için aşağıdaki bağlantıya tıklayınız. 
    https://filokar.com/gbvfdsrfdc?c=${loadedItem.id}`;

    // // const user = loadedItem.user.id.toString();
    // const user = "61e3c7a2cd698a001617f59e";
    // // const foundPhone = loadedItem.user.phonenumber;
    // const foundPhone = "5355802151";
    const phone = formStatePhoneNumber.inputs.phonenumber.value.trim();
    const date = formatDate(new Date());
    let text = message;
    let smsData = { user: parent, phone, text, date };

    // try {
    //   const messageResponse = await sendRequest(
    //     `${process.env.REACT_APP_BACKEND_URL}/gfhhdethjjdsd`,
    //     "POST",
    //     JSON.stringify(smsData),
    //     {
    //       "Content-Type": "application/json",
    //       Authorization: "Bearer " + auth.token,
    //     }
    //   );
    // } catch (err) {}

    // text = message;
    // smsData = { user: parent, phone, text, date };

    try {
      const messageResponse = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/gfhhdethjjdsd`,
        "POST",
        JSON.stringify(smsData),
        {
          "Content-Type": "application/json",
          Authorization: "Bearer " + auth.token,
        }
      );
    } catch (err) {}

    setOpenSmsSend(true);
  });

  const SendSMS = async () => {
    const ioslink = "https://tinyurl.com/servistesinios";
    const androidlink = "https://tinyurl.com/servistesinandroid";

    //const message = `Sayın Kullanıcımız, Servistesin uygulaması üzerinde yeni sözleşmeniz oluşturulmuştur. Lütfen uygulama üzerinden sözleşmenizi ve durak bilgilerinizi kontrol ediniz. Uygulamamıza, Android işletim sistemleri için ${androidlink} Apple IOS işletim sistemleri için ${ioslink} bağlantılarından erişebilirsiniz.`;
    const message = `Sözleşmeniz güncellenmiştir. Lütfen Servistesin uygulaması üzerinden durak bilgilerinizi kontrol ediniz. Çetin Tur`;

    const foundPhone = loadedItem.user.phonenumber;

    const user = loadedItem.user.id.toString();
    const student = loadedItem.student.toString();
    const vehicleRoute = "62e32f313da7b2508040c4d7";
    const phone = foundPhone;
    const text = message;
    const date = formatDate(new Date());
    const status = 1;

    const smsData = { user, student, phone, text, date, status, vehicleRoute };

    try {
      const messageResponse = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/xrytutdvsdfds`,
        "POST",
        JSON.stringify(smsData),
        {
          "Content-Type": "application/json",
          Authorization: "Bearer " + auth.token,
        }
      );
    } catch (err) {}
  };

  const itemUpdateSubmitHandler = useCallback(async (event) => {
    event.preventDefault();
    const isSendSms = formState.inputs.userApproved.value;
    let responseData;

    let specialDiscountToWrite = 0;
    try {
      specialDiscountToWrite = parseInt(formState.inputs.specialDiscount.value);
    } catch (err) {
      specialDiscountToWrite = 0;
    }

    try {
      responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/${ItemEndPointType}/${itemId}`,
        "PATCH",
        JSON.stringify({
          payerName: formState.inputs.payerName.value,
          payerIdentity: formState.inputs.payerIdentity.value,
          payerAddress: formState.inputs.payerAddress.value,
          payerMail: formState.inputs.payerMail.value,
          student: formState.inputs.student.value,
          year: formState.inputs.year.value,
          date: formState.inputs.date.value,
          contractEnabled: formState.inputs.contractEnabled.value,
          serviceTemplate: formState.inputs.serviceTemplate.value,
          serviceStartDate: formState.inputs.serviceStartDate.value,
          serviceEndingDate: formState.inputs.serviceEndingDate.value,
          totalAmount: formState.inputs.totalAmount.value,
          isCompanyReceipt: formState.inputs.isCompanyReceipt.value,
          taxNo: formState.inputs.taxNo.value,
          taxOffice: formState.inputs.taxOffice.value,
          companyTitle: formState.inputs.companyTitle.value,
          standardDiscounts: formState.inputs.standardDiscounts.value,
          specialDiscount: specialDiscountToWrite,
          specialDiscountDescription: formState.inputs.specialDiscountDescription.value,
          userApproved: formState.inputs.userApproved.value,
          finalAmount: formState.inputs.finalAmount.value,
        }),
        {
          "Content-Type": "application/json",
          Authorization: "Bearer " + auth.token,
        }
      );

      // if (isSendSms) {
      //   await SendSMS();
      // }

      setLoadedItem(responseData[ItemType]);
      console.log(responseData[ItemType]);

      setItemState(Date.now());
      updateStoreItems();
      //store.UpdateAccountingUtils();

      // history.push(`/${ItemEndPointType}`);
    } catch (err) {}
  });

  const applyServiceTemplate = useCallback(
    async (tey) => {
      const templateId = formState.inputs.serviceTemplate.value;
      const currentServiceTemplate = store.serviceTemplates.find(
        (serviceTemplate) => serviceTemplate.id === templateId
      );
      if (!currentServiceTemplate) {
        return;
      }

      setServiceStartDate(currentServiceTemplate.serviceStartDate);
      setServiceStartDateKey(currentServiceTemplate.serviceStartDate + "-" + Math.random());
      inputHandler("serviceStartDate", currentServiceTemplate.serviceStartDate, true);
      loadedItem.serviceStartDate = currentServiceTemplate.serviceStartDate;

      setServiceEndingDate(currentServiceTemplate.serviceEndingDate);
      setServiceEndingDateKey(currentServiceTemplate.serviceEndingDate + "-" + Math.random());
      inputHandler("serviceEndingDate", currentServiceTemplate.serviceEndingDate, true);
      loadedItem.serviceEndingDate = currentServiceTemplate.serviceEndingDate;

      setEducationYear(currentServiceTemplate.year.toString());
      setEducationYearKey(currentServiceTemplate.educationYear + "-" + Math.random());
      inputHandler("educationYear", currentServiceTemplate.educationYear, true);
      loadedItem.year = currentServiceTemplate.year.toString();
      //initialValue={store.educationYearsOptions.find((item) => item.value.toString() === educationYear)}

      const today = new Date();
      const date = parseComplexDate(today);
      setContractDate(date);
      setContractDateKey(date + "-" + Math.random());
      inputHandler("date", date, true);
      loadedItem.date = date;

      setTotalAmount(currentServiceTemplate.amount);
      setTotalAmountKey(currentServiceTemplate.amount + "-" + Math.random());
      inputHandler("totalAmount", currentServiceTemplate.amount, true);
      loadedItem.totalAmount = currentServiceTemplate.amount;
    },
    [formState.inputs.serviceTemplate.value, store.serviceTemplates]
  );

  const calculateFinalAmount = useCallback(async () => {
    const totalAmountHere = parseFloat(formState.inputs.totalAmount.value);
    const specialDiscount = parseFloat(formState.inputs.specialDiscount.value);
    const standardDiscounts = formState.inputs.standardDiscounts.value;

    let calculatedAmount = totalAmountHere;

    standardDiscounts.forEach((discount) => {
      const currentDiscount = store.discounts.find((item) => item.id.toString() === discount.toString());

      //1 --- Percentage Discount
      if (currentDiscount.type === "1") {
        calculatedAmount = calculatedAmount - (calculatedAmount * currentDiscount.amount) / 100;
      }

      //2 --- Constant Discount
      if (currentDiscount.type === "2") {
        calculatedAmount = calculatedAmount - currentDiscount;
      }
    });

    if (specialDiscount) {
      calculatedAmount = calculatedAmount - specialDiscount;
    }
    //setEducationYear(0);
    loadedItem.finalAmount = calculatedAmount;
    setFinalAmount(parseInt(calculatedAmount));
    setFinalAmountKey(parseInt(calculatedAmount) + "-" + Math.random());
    inputHandler("finalAmount", parseInt(calculatedAmount), true);
  }, [
    formState.inputs.totalAmount.value,
    formState.inputs.specialDiscount.value,
    formState.inputs.standardDiscounts.value,
  ]);

  const companyReceiptUIHandler = (value) => {
    setIsCompanyReceipt(value);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSmsSend(false);
  };

  if (isLoading) {
    return (
      <div className="center">
        <LoadingSpinner />
      </div>
    );
  }

  if (!loadedItem && !error) {
    return (
      <div className="center">
        <Card>
          <h2>{ItemTypeTR} bulunamadı.</h2>
        </Card>
      </div>
    );
  }

  return (
    <React.Fragment>
      <ErrorModal error={error} onClear={clearError} />
      <Modal
        show={showConfirmModal}
        onCancel={cancelDeleteHandler}
        header="Emin misiniz?"
        footerClass="place-item__modal-actions"
        footer={
          <React.Fragment>
            <Button inverse onClick={cancelDeleteHandler}>
              Hayır
            </Button>
            <Button danger onClick={confirmDeleteHandler}>
              Evet
            </Button>
          </React.Fragment>
        }
      >
        <p>{ItemTypeTR} silme işlemini onaylıyor musunuz? Bu işlem geri alınamaz.</p>
      </Modal>

      <Modal
        show={showSmsModal}
        onCancel={cancelSMSHandler}
        header="Emin misiniz?"
        footerClass="place-item__modal-actions"
        footer={
          <React.Fragment>
            <Button inverse onClick={cancelSMSHandler}>
              Hayır
            </Button>
            <Button danger onClick={sendContractApprovalLink}>
              Evet
            </Button>
          </React.Fragment>
        }
      >
        <p>Kullanıcıya SMS ile sözleşme ve onay linki gönderilecek. Onaylıyor musunuz? Bu işlem geri alınamaz.</p>
        <p>
          Telefon numarası: <b>{formStatePhoneNumber.inputs.phonenumber.value.trim()}</b>
        </p>
      </Modal>

      {!isLoading && loadedItem && (
        <form key={itemstate} className="item-form-accounting" onSubmit={itemUpdateSubmitHandler}>
          <div className="button-container">
            <Button type="button" danger onClick={props.onClose}>
              &#60; Geri
            </Button>
          </div>

          {!Authorization.canUpdateContract && (
            <div>
              {/* <br />
            Kullanıcıya SMS ile sözleşme ve onay linki gönderebilirsiniz.  */}
              <br />
              <b>Sözleşme koşullarını güncelleme yetkiniz bulunmuyor.</b>
              <br />
            </div>
          )}

          <CustomSelect
            options={store.serviceTemplateOptions}
            id="serviceTemplate"
            onInput={inputHandler}
            label="Hizmet Şablonu"
            initialValid={true}
            initialValue={store.serviceTemplateOptions.find(
              (item) => item.value.toString() === loadedItem.serviceTemplate.toString()
            )}
          ></CustomSelect>

          <Button type="button" danger onClick={applyServiceTemplate.bind(this, "apply")}>
            Şablonu Uygula
          </Button>

          <div className="groupContainer-accounting">
            <CustomSelect
              options={store.studentOptions}
              id="student"
              onInput={inputHandler}
              label="Öğrenci"
              initialValid={true}
              initialValue={store.studentOptions.find(
                (item) => item.value.toString() === loadedItem.student.toString()
              )}
            ></CustomSelect>

            <Input
              id="payerName"
              element="input"
              type="text"
              label="Kontrat Sahibi"
              validators={[VALIDATOR_NONE()]}
              initialValid={true}
              initialValue={loadedItem.payerName.toString()}
              onInput={inputHandler}
            />

            <Input
              id="payerIdentity"
              element="input"
              type="text"
              label="Kimlik Numarası"
              validators={[VALIDATOR_NONE()]}
              initialValid={true}
              initialValue={loadedItem.payerIdentity.toString()}
              onInput={inputHandler}
            />

            <Input
              id="payerMail"
              element="input"
              type="text"
              label="Mail Adresi"
              validators={[VALIDATOR_NONE()]}
              initialValid={true}
              initialValue={loadedItem.payerMail.toString()}
              onInput={inputHandler}
            />

            <Input
              id="payerAddress"
              element="input"
              type="text"
              label="İletişim Adresi"
              validators={[VALIDATOR_NONE()]}
              initialValid={true}
              initialValue={loadedItem.payerAddress.toString()}
              onInput={inputHandler}
            />

            <CustomSelect
              options={isCompanyReceiptOptions}
              id="isCompanyReceipt"
              onInput={inputHandler}
              label="Şirket Faturası mı?"
              initialValue={isCompanyReceiptOptions.find(
                (option) => option.value.toString() === loadedItem.isCompanyReceipt.toString()
              )}
              initialValid={true}
              fireChange={companyReceiptUIHandler}
            ></CustomSelect>

            {isCompanyReceipt && (
              <Input
                id="taxNo"
                element="input"
                type="text"
                label="Vergi Numarası"
                initialValue={loadedItem.taxNo.toString()}
                onInput={inputHandler}
                validators={[VALIDATOR_NONE()]}
                initialValid={true}
              />
            )}

            {isCompanyReceipt && (
              <Input
                id="taxOffice"
                element="input"
                type="text"
                label="Vergi Dairesi"
                initialValue={loadedItem.taxOffice.toString()}
                onInput={inputHandler}
                validators={[VALIDATOR_NONE()]}
                initialValid={true}
              />
            )}
            {isCompanyReceipt && (
              <Input
                id="companyTitle"
                element="input"
                type="text"
                label="Şirket Ünvanı"
                initialValue={loadedItem.companyTitle.toString()}
                onInput={inputHandler}
                validators={[VALIDATOR_NONE()]}
                initialValid={true}
              />
            )}
          </div>

          <div className="groupContainer">
            <CustomSelect
              key={educationYearKey}
              options={store.educationYearsOptions}
              id="year"
              onInput={inputHandler}
              label="Eğitim Öğretim Dönemi"
              initialValid={true}
              initialValue={store.educationYearsOptions.find(
                (item) => item.value.toString() === loadedItem.year.toString()
              )}
            ></CustomSelect>

            <DatePicker
              key={contractDateKey}
              id="date"
              onInput={inputHandler}
              label="Sözleşme Tarihi"
              initialValid={true}
              //initialValue={contractDate}
              initialValue={loadedItem.date.toString()}
            ></DatePicker>

            <DatePicker
              key={serviceStartDateKey}
              id="serviceStartDate"
              onInput={inputHandler}
              label="Hizmet Başlangıç Tarihi"
              initialValid={true}
              initialValue={loadedItem.serviceStartDate.toString()}
            ></DatePicker>

            <DatePicker
              key={serviceEndingDateKey}
              id="serviceEndingDate"
              onInput={inputHandler}
              label="Hizmet Bitiş Tarihi"
              validators={[VALIDATOR_REQUIRE()]}
              initialValid={true}
              initialValue={loadedItem.serviceEndingDate.toString()}
            ></DatePicker>
          </div>

          <div className="groupContainer">
            <CustomSelect
              options={store.discountOptions}
              id="standardDiscounts"
              onInput={inputHandler}
              label="Uygulanacak Standart İndirimler"
              initialValid={true}
              isMulti={true}
              initialValue={store.discountOptions.filter((option) =>
                loadedItem.standardDiscounts.includes(option.value)
              )}
            ></CustomSelect>

            <Input
              id="specialDiscount"
              element="input"
              type="text"
              label="Özel İndirim Tutarı"
              validators={[VALIDATOR_NONE()]}
              initialValid={true}
              initialValue={loadedItem.specialDiscount.toString()}
              onInput={inputHandler}
            />

            <Input
              id="specialDiscountDescription"
              element="input"
              type="text"
              label="Özel İndirim Açıklaması"
              validators={[VALIDATOR_NONE()]}
              initialValid={true}
              initialValue={loadedItem.specialDiscountDescription.toString()}
              onInput={inputHandler}
            />
          </div>

          <CustomSelect
            key={userApprovedKey}
            options={userApproved}
            id="userApproved"
            onInput={inputHandler}
            label="Kullanıcı sözleşmeyi onayladı mı?"
            initialValue={userApproved.find((item) => item.value.toString() === loadedItem.userApproved.toString())}
            validators={[VALIDATOR_REQUIRE()]}
            initialValid={true}
            disabled
          ></CustomSelect>

          <Input
            id="phonenumber"
            element="input"
            type="text"
            label="Veli Telefonu"
            validators={[VALIDATOR_PHONENUMBER(10)]}
            errorText="5 ile başlayan 10 haneli telefon numarası giriniz."
            initialValid={true}
            onInput={inputHandlerPhoneNumber}
            initialValue={
              phoneNumber ? phoneNumber : "Öğrenci - Veli eşleşmesi bulunmadı. Bildirimler sekmesini tamamlayınız."
            }
          />

          <div className="button-container">
            <Button type="button" danger onClick={showSMSHandler} disabled={!phoneNumber}>
              Kullanıcıya sözleşme onayı gönder
            </Button>

            <Button type="button" danger onClick={checkContractStatus.bind(this, "apply")}>
              Sözleşme onayı durumunu güncelle
            </Button>
          </div>

          <Snackbar
            open={openSmsSend}
            autoHideDuration={2000}
            onClose={handleClose}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          >
            <MuiAlert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
              Kullanıcıya sms bilgilendirmesi ile sözleşme onayı gönderildi.
            </MuiAlert>
          </Snackbar>

          <Input
            key={totalAmountKey}
            id="totalAmount"
            element="input"
            type="text"
            label="Standart Hizmet Ücreti"
            validators={[VALIDATOR_REQUIRE()]}
            errorText="Hizmet ücretini giriniz."
            onInput={inputHandler}
            initialValue={loadedItem.totalAmount.toString()}
            initialValid={true}
          />

          <Button type="button" danger onClick={calculateFinalAmount}>
            Ödenecek Ücreti Hesapla
          </Button>

          <Input
            key={finalAmountKey}
            id="finalAmount"
            element="input"
            type="text"
            label="Ödenecek Toplam"
            validators={[VALIDATOR_REQUIRE()]}
            errorText="Üstteki hizmet ücretini giriniz."
            onInput={inputHandler}
            initialValid={finalAmount}
            //initialValue={finalAmount}
            initialValue={loadedItem.finalAmount.toString()}
            disabled={true}
          />

          <div className="button-container">
            <Button type="submit" disabled={!formState.isValid || !Authorization.canUpdateContract}>
              Güncelle
            </Button>

            <Button type="button" danger disabled={!Authorization.canUpdateContract} onClick={showDeleteWarningHandler}>
              SİL
            </Button>
          </div>
        </form>
      )}
    </React.Fragment>
  );
};

export default ContractItem;
