import React, { useContext, useState, useRef, useEffect } from "react";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Accordion from "@mui/material/Accordion";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Dragger from "./dragger/Dragger";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import CheckIcon from "@mui/icons-material/Check";

// #region Components
import ExcelUpload from "shared/components/FormElements/ExcelUpload";
import Button from "shared/components/FormElements/Button";
import CustomSelect from "shared/components/FormElements/Select";
import LoadingSpinner from "shared/components/UIElements/LoadingSpinner";
import ErrorModal from "shared/components/UIElements/ErrorModal";
// #endregion Components

// #region Utils
import { VALIDATOR_REQUIRE } from "shared/util/validators";
// #endregion Utils

// #region Hooks
import { useForm } from "shared/hooks/form-hook";
import { useHttpClient } from "shared/hooks/http-hook";
import { AuthContext } from "shared/context/auth-context";
import { StoreContext } from "shared/context/store-context";
// #endregion Hooks

import "./Item.css";

const TransferNewExcel = (props) => {
  const auth = useContext(AuthContext);
  const store = useContext(StoreContext);
  const [haveResponse, setHaveResponse] = useState(false);

  const [planes, setPlanes] = useState([]);
  const [finishedPlanes, setFinishedPlanes] = useState([]);
  const isFetchingRef = useRef(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const [isProcessing, setIsProcessing] = useState(false);
  const [processId, setProcessId] = useState(null);
  const isProcessingRef = useRef(false);
  const processIdRef = useRef(null);

  const [showAlert, setShowAlert] = useState(false);
  const alertMessageRef = useRef("");

  const { isLoading, sendRequest } = useHttpClient();

  useEffect(() => {
    const fetchProcessState = async () => {
      if (!processIdRef.current || !isProcessingRef.current || isFetchingRef.current) {
        return;
      }
      isFetchingRef.current = true;
      try {
        const resp = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/process/${processIdRef.current}`,
          "GET",
          null,
          { Authorization: "Bearer " + auth.token }
        );

        if (resp?.process?.processing === false && resp?.process?.errorCode === 0) {
          await getFlightResults(resp.process);
        } else if (resp?.process?.processing === false && resp?.process?.errorCode === 1) {
          setErrorMessage(resp.process.error);
        }
        isProcessingRef.current = resp.process.processing;
        setIsProcessing(resp.process.processing);
      } catch (err) {}
      isFetchingRef.current = false;
    };
    fetchProcessState();

    const intervalId = setInterval(fetchProcessState, 5000);

    return () => {
      clearInterval(intervalId);
    };
  }, [processId, sendRequest, auth.token]);

  const getFlightResults = async (response) => {
    try {
      if (response?.data?.flights) {
        response.data.transfers.forEach((transfer) => {
          const nullPerson = transfer.people.find((person) => person.id === null);
          if (nullPerson) {
          }
        });

        let currentPlanes = [];
        for (let i = 0; i < response.data.flights.length; i++) {
          const currentFlight = response.data.flights[i];
          for (let j = 0; j < currentFlight.routes.length; j++) {
            const currentRoute = currentFlight.routes[j];

            currentPlanes.push({
              flightType: currentFlight.flightType,
              people: currentFlight.people,
              planeName: currentRoute.routeName,
              planeType: currentRoute.routeType,
              planeDepartureTime: currentRoute.routeDepartureTime,
              planeArrivalTime: currentRoute.routeArrivalTime,
              airportTime: currentRoute.airportTime,
              transfers: response.data.transfers.find(
                (transfer) =>
                  transfer.routeName === currentRoute.routeName && transfer.airportTime === currentRoute.airportTime
              ),
            });
          }
        }
        setPlanes(currentPlanes);
        setHaveResponse(true);
      }
    } catch (err) {}
  };

  const itemSubmitHandler = async (event) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append("school", formState.inputs.school.value);
    formData.append("excel", formState.inputs.excel.value);

    let distributionProcessId = null;

    try {
      const response = await sendRequest(`${process.env.REACT_APP_BACKEND_URL}/transferfile`, "POST", formData);
      distributionProcessId = response.process.id;
    } catch (err) {}

    if (!distributionProcessId) {
      return;
    }

    setIsProcessing(true);
    isProcessingRef.current = true;
    setProcessId(distributionProcessId);
    processIdRef.current = distributionProcessId;
  };

  const [formState, inputHandler] = useForm(
    {
      school: { value: "", isValid: false },
      excel: { value: null, isValid: false },
    },
    false
  );

  const handleTransferCreated = (planeName, planeDepartureTime) => {
    setFinishedPlanes([...finishedPlanes, { planeName, planeDepartureTime }]);
  };

  const finishedComponent = (plane) =>
    finishedPlanes.find(
      (finishedPlane) =>
        finishedPlane.planeName === plane.planeName && finishedPlane.planeDepartureTime === plane.planeDepartureTime
    ) ? (
      <div
        style={{ display: "inline-flex", flexDirection: "row", color: "green", marginLeft: "10px", fontWeight: "bold" }}
      >
        Tamamlandı
        <CheckIcon
          style={{
            textAlign: "center",
            transform: "translate(0, -30%)",
            position: "relative",
          }}
          sx="green"
          fontSize="large"
        />
      </div>
    ) : (
      ""
    );

  if (isLoading || isProcessing || !store.studentOptions) {
    return (
      <div className="center">
        <LoadingSpinner />
      </div>
    );
  }

  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setShowAlert(false);
  };

  const clearErrorMessage = () => {
    setErrorMessage(null);
  };

  return (
    <React.Fragment>
      <ErrorModal error={errorMessage} onClear={clearErrorMessage} />

      <Snackbar
        open={showAlert}
        autoHideDuration={2000}
        onClose={handleAlertClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <MuiAlert onClose={handleAlertClose} severity="error" sx={{ width: "100%" }}>
          {alertMessageRef.current}
        </MuiAlert>
      </Snackbar>

      <div className="excel-transfer-container">
        <form className="new-transfer-item" style={{ margin: "0px" }} onSubmit={itemSubmitHandler}>
          {(isLoading || isProcessing || !store.studentOptions) && <LoadingSpinner asOverlay />}
          <CustomSelect
            options={store.schoolOptions}
            id="school"
            onInput={inputHandler}
            label="Kurum"
            value={formState.inputs.school}
            initialValue={store.schoolOptions[0]}
            validators={[VALIDATOR_REQUIRE()]}
          ></CustomSelect>

          <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center" }}>
            <div>
              <ExcelUpload id="excel" onInput={inputHandler}></ExcelUpload>
            </div>
            <div style={{ marginLeft: "30px" }}>
              <Button type="submit" disabled={!formState.isValid}>
                Dosyayı Kontrol Et
              </Button>
            </div>
          </div>
        </form>

        {haveResponse && (
          <div className="new-transfer-item">
            {planes.map((plane) => (
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                  <p>
                    {plane.planeType.toString() === "100" ? (
                      <div>
                        <b>Uçuş: </b> {plane.planeName} {"   |   "}
                        <b>Kalkış: </b> {plane.planeDepartureTime} {"   |   "}
                        <b>İniş: </b> {plane.planeArrivalTime} {"   |   "}
                        <b>Havaalanında Olma:</b> {plane.airportTime} {"   |   "}
                        {finishedComponent(plane)}
                      </div>
                    ) : (
                      <div>
                        <b>Uçuş: </b> {plane.planeName} {"   |   "}
                        <b>Kalkış: </b> {plane.planeDepartureTime} {"   |   "}
                        <b>İniş: </b> {plane.planeArrivalTime} {"   |   "}
                        <b>Havaalanında Olma:</b> {plane.airportTime} {"   |   "}
                        <b>{finishedComponent(plane)}</b>
                      </div>
                    )}
                  </p>
                </AccordionSummary>
                <AccordionDetails display="flex">
                  <DndProvider backend={HTML5Backend}>
                    <Dragger
                      school={formState.inputs.school.value}
                      transfers={plane.transfers}
                      onTransferCreated={() => handleTransferCreated(plane.planeName, plane.planeDepartureTime)}
                    />
                  </DndProvider>
                </AccordionDetails>
              </Accordion>
            ))}
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default TransferNewExcel;
