import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid";
import { days, days_en } from "shared/util/types";
import { ThemeProvider } from "@mui/material/styles";
import { AuthContext } from "shared/context/auth-context";
import i18next from "i18next";

const ShiftsList = (props) => {
  const { t } = useTranslation();
  const auth = useContext(AuthContext);
  const language = (i18next?.language || window?.localStorage?.i18nextLng || "en").substring(0, 2);

  const visibilityJSON = localStorage.getItem("shiftmanagementlist_visibility");
  const visibilityModel = visibilityJSON ? JSON.parse(visibilityJSON) : {};
  const [columnVisibilityModel, setColumnVisibilityModel] = useState(visibilityModel);
  const filterJSON = localStorage.getItem("shiftmanagementlist_filter");
  const initialFilterModel = filterJSON ? JSON.parse(filterJSON) : { items: [] };
  const [filterModel, setFilterModel] = useState(initialFilterModel);

  const columns = [
    {
      field: "day",
      sortable: false,
      headerName: t("day"),
      width: 200,
      valueGetter: (params) => {
        try {
          return language === "en"
            ? days_en.find((day) => day.value.toString() === params.row.day.toString()).label
            : days.find((day) => day.value.toString() === params.row.day.toString()).label;
        } catch (e) {
          return "";
        }
      },
    },
    { field: "name", headerName: t("name"), width: 300, sortable: false },
    { field: "starttime", headerName: t("startTime"), width: 200, sortable: false },
    { field: "endtime", headerName: t("endTime"), width: 200, sortable: false },
  ];

  const OnSelectionChangeHandler = (event) => {
    props.onItemSelect(event[0]);
  };

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarExport csvOptions={{ utf8WithBom: true }} />
      </GridToolbarContainer>
    );
  }

  return (
    <div className="itemListContainer">
      <ThemeProvider theme={auth.theme}>
        <DataGrid
          columnVisibilityModel={columnVisibilityModel}
          onColumnVisibilityModelChange={(newModel) => {
            localStorage.setItem("shiftmanagementlist_visibility", JSON.stringify(newModel));
            setColumnVisibilityModel(newModel);
          }}
          filterModel={filterModel}
          onFilterModelChange={(newModel) => {
            localStorage.setItem("shiftmanagementlist_filter", JSON.stringify(newModel));
            setFilterModel(newModel);
          }}
          key={props.items}
          density="compact"
          components={{
            Toolbar: CustomToolbar,
          }}
          rows={props.items}
          columns={columns}
          pageSize={100}
          getRowId={(r) => r.id}
          onSelectionModelChange={OnSelectionChangeHandler}
          selectionModel={props?.selectedId ? [props.selectedId] : []}
          paginationModel={"server"}
          keepNonExistentRowsSelected
        />
      </ThemeProvider>
    </div>
  );
};

export default ShiftsList;
