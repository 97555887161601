import React, { useState, useContext } from "react";
import Vehicle from "./Vehicle";
import Button from "shared/components/FormElements/Button";
import { AIRPORT_ID, tsp } from "shared/util/location";
import { CheckTimeString } from "shared/util/time";
import { useHttpClient } from "shared/hooks/http-hook";
import { AuthContext } from "shared/context/auth-context";
import { StoreContext } from "shared/context/store-context";
import Modal from "shared/components/UIElements/Modal";
import LoadingSpinner from "shared/components/UIElements/LoadingSpinner";

const Dragger = (props) => {
  //eslint-disable-next-line
  const [people, setPeople] = useState(props.transfers.routes.map((route) => route.people).flat(1));
  const [vehicles, setVehicles] = useState(props.transfers.routes);
  const [transferCreated, setTransferCreated] = useState(false);
  const [isTransferCreating, setIsTransferCreating] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const store = useContext(StoreContext);

  const { sendRequest } = useHttpClient();
  const auth = useContext(AuthContext);

  const [results, setResults] = useState(
    props.transfers.routes.map((vehicle) => {
      return { id: vehicle.info.transferId };
    })
  );
  const handleDrop = (personId, vehicleId) => {
    const selectedPerson = people.find((person) => person.id === personId);
    const updatedVehicles = vehicles.map((vehicle) => {
      if (vehicle.info.transferId === vehicleId) {
        if (vehicle.people.find((person) => person.id === personId)) {
          return vehicle;
        }
        return {
          ...vehicle,
          people: [...vehicle.people, selectedPerson],
        };
      } else {
        return {
          ...vehicle,
          people: [...vehicle.people.filter((person) => person.id !== personId)],
        };
      }
    });
    setVehicles(updatedVehicles);
    copyVehiclesToResults(updatedVehicles);
  };

  const personDownHandler = (vehicleId, personId) => {
    const updatedVehicles = vehicles.map((vehicle) => {
      if (vehicle.info.transferId === vehicleId) {
        const pos = vehicle.people.map((person) => person.id).indexOf(personId);
        let newPeople = [];

        for (let i = 0; i < vehicle.people.length; i++) {
          if (i === pos && i !== vehicle.people.length - 1) {
            newPeople.push(vehicle.people[i + 1]);
            newPeople.push(vehicle.people[i]);
            i++;
            continue;
          } else {
            newPeople.push(vehicle.people[i]);
          }
        }
        return {
          ...vehicle,
          people: [...newPeople],
        };
      } else {
        return {
          ...vehicle,
        };
      }
    });
    setVehicles(updatedVehicles);
    copyVehiclesToResults(updatedVehicles);
  };

  const personUpHandler = (vehicleId, personId) => {
    const updatedVehicles = vehicles.map((vehicle) => {
      if (vehicle.info.transferId === vehicleId) {
        const pos = vehicle.people.map((person) => person.id).indexOf(personId);
        let newPeople = [];

        for (let i = 0; i < vehicle.people.length; i++) {
          if (i === pos && i !== 0) {
            const prev = newPeople.pop();
            newPeople.push(vehicle.people[i]);
            newPeople.push(prev);
            continue;
          } else {
            newPeople.push(vehicle.people[i]);
          }
        }
        return {
          ...vehicle,
          people: [...newPeople],
        };
      } else {
        return {
          ...vehicle,
        };
      }
    });
    setVehicles(updatedVehicles);
    copyVehiclesToResults(updatedVehicles);
  };

  const deletePersonHandler = (vehicleId, personId) => {
    const updatedVehicles = vehicles.map((vehicle) => {
      if (vehicle.id === vehicleId) {
        return {
          ...vehicle,
          people: [...vehicle.people.filter((person) => person.id !== personId)],
        };
      }
      return vehicle;
    });

    setVehicles(updatedVehicles);
    copyVehiclesToResults(updatedVehicles);
  };

  const changeVehicleName = (vehicleId, newName) => {
    const updatedVehicles = vehicles.map((vehicle) => {
      if (vehicle.info.transferId === vehicleId) {
        return {
          ...vehicle,
          info: {
            ...vehicle.info,
            transferName: newName,
          },
        };
      }
      return vehicle;
    });
    setVehicles(updatedVehicles);
    copyVehiclesToResults(updatedVehicles);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const optimizeHandler = (vehicleId) => {
    const updatedVehicles = vehicles.map((vehicle) => {
      if (vehicle.info.transferId === vehicleId) {
        const currentPeople = vehicle.people;
        const result = tsp(
          AIRPORT_ID,
          currentPeople.map((person) => person.id),
          props.transfers.distancesInformation,
          props.transfers.routeType
        );
        let newPeople = [];
        for (let i = 0; i < result.route.length; i++) {
          const personId = result.route[i];
          const person = currentPeople.find((person) => person.id === personId);
          newPeople.push(person);
        }
        return {
          ...vehicle,
          people: [...newPeople],
        };
      }
      return vehicle;
    });
    setVehicles(updatedVehicles);
    copyVehiclesToResults(updatedVehicles);
  };

  const copyVehiclesToResults = (currentVehicles) => {
    const updatedResults = currentVehicles.map((vehicle) => {
      return {
        id: vehicle.info.transferId,
        people: [...vehicle.people],
        driver: vehicle.driver.driver,
        transferName: vehicle.info.transferName,
      };
    });
    setResults(updatedResults);
  };

  const saveResultHandler = (vehicleId, calculatedPeople, driver, transferName) => {
    const updatedResults = results.map((result) => {
      if (result.id === vehicleId) {
        return {
          ...result,
          people: [...calculatedPeople],
          driver: driver,
          transferName: transferName,
        };
      }
      return result;
    });
    setResults(updatedResults);
  };

  const createTransfer = async () => {
    setIsTransferCreating(true);
    let problems = "";

    if (!results.length) {
      setErrorMessage("Uçuşta transfer bulunmuyor. Bu uçuş için transfer oluşturamazsınız.");
      setShowModal(true);
      setIsTransferCreating(false);
      return;
    }

    for (let i = 0; i < results.length; i++) {
      const result = results[i];

      if (!result?.driver) {
        problems += result.transferName + " için sürücü seçilmemiş.\n";
      }

      if (!result?.transferName?.length) {
        problems += "Girilmemiş transfer ismi bulunuyor.\n";
      }

      if (!result?.people?.length) {
        problems += result.transferName + " için yolcu bulunmuyor. Bu aracı siliniz ya da tekrar düzenleyiniz.\n";
      }

      for (let j = 0; j < result.people.length; j++) {
        const person = result.people[j];
        if (!person?.lat || !person?.lng) {
          problems += result.transferName + " için " + person.name + " isimli yolcunun konum bilgileri bulunmuyor.\n";
        }

        if (props.transfers.routeType === 100 && !CheckTimeString(person.pickupTime)) {
          problems += result.transferName + " için " + person.name + " isimli yolcunun pickup saati hatalı.\n";
        }
      }
    }

    if (problems.length) {
      setErrorMessage(problems);
      setShowModal(true);
      setIsTransferCreating(false);
      return;
    }

    let vehicleRouteIds = [];

    for (let i = 0; i < results.length; i++) {
      const result = results[i];
      const transfername = result?.transferName;
      const transfertype = props.transfers.routeType;
      const peopleIds = result.people.map((person) => person.id);

      const personIdentifications = result.people.map((person) => {
        return {
          student: person.id,
          transfertime: transfertype === 100 ? person.pickupTime : "",
          transferregion: "633c2deba1209e01d878faf2",
        };
      });

      try {
        const vehicleRouteData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/vehicleroutes`,
          "POST",
          JSON.stringify({
            name: transfername,
            type: transfertype,
            school: props.school,
            driver: result.driver, //HERE
            plate: "07C0000", //HERE
            starttime: "00:01",
            stoptime: "23:59",
            student: peopleIds,
            status: 1,
            location: { lat: -1.1, lng: -1.1 },
            routelocations: [
              {
                student: "2",
                transfertime: "2",
                transferregion: "633c2deba1209e01d878faf2",
              },
              ...personIdentifications,
            ],
          }),
          {
            "Content-Type": "application/json",
            Authorization: "Bearer " + auth.token,
          }
        );

        const newVehicleRouteId = vehicleRouteData.vehicleRoute._id.toString();
        vehicleRouteIds.push(newVehicleRouteId);
      } catch (err) {
        problems += result.transferName + " için transfer aracı oluşturulamadı.\n";
      }

      if (problems.length) {
        setErrorMessage(problems);
        setShowModal(true);
        setIsTransferCreating(false);
        return;
      }
    }

    try {
      await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/transfers`,
        "POST",
        JSON.stringify({
          name: props.transfers.routeName,
          type: props.transfers.routeType,
          school: props.school,
          transferdate: props.transfers.airportTime.split(" ")[0],
          transfertime: props.transfers.airportTime.split(" ")[1],
          done: true,
          vehicleRoutes: vehicleRouteIds,
        }),
        {
          "Content-Type": "application/json",
          Authorization: "Bearer " + auth.token,
        }
      );

      //history.push(`/${ItemEndPointType}`);
    } catch (err) {
      problems += "Transfer oluşturulamadı.\n";
    }

    if (problems.length) {
      setErrorMessage(problems);
      setShowModal(true);
      setIsTransferCreating(false);
      return;
    }

    store.UpdateVehicleRoutes();
    store.UpdateTransfers();

    await new Promise((resolve) => setTimeout(resolve, 7000));

    setTransferCreated(true);
    setIsTransferCreating(false);
    props.onTransferCreated();
  };

  const addVehicleHandler = () => {
    const transferdate = props.transfers.airportTime.split(" ")[0];
    const transfertime = props.transfers.airportTime.split(" ")[1];
    const strings_transferdate_month = transferdate.split("-")[1];
    const strings_transferdate_day = transferdate.split("-")[2];
    const currentTransferName =
      strings_transferdate_day +
      "." +
      strings_transferdate_month +
      "-" +
      transfertime +
      " --- " +
      (vehicles.length + 1);
    const currentTransferId = Math.random().toString(36).substring(7);

    const newVehicle = {
      info: {
        transferId: currentTransferId,
        transferName: currentTransferName,
      },
      people: [],
      driver: { driver: "63299827ef13360016a66e81" },
    };
    setVehicles((prevVehicles) => {
      return [...prevVehicles, newVehicle];
    });

    setResults((prevResults) => {
      return [...prevResults, { id: currentTransferId, ...newVehicle }];
    });
  };

  const deleteVehicleHandler = (vehicleId) => {
    const selectedVehicle = vehicles.find((vehicle) => vehicle.info.transferId === vehicleId);

    if (selectedVehicle?.people.length > 0) {
      alert("Bu transferde yolcu bulunmaktadır. Silmeden önce yolcuları  kaldırmanız gerekmektedir.");
    } else {
      setVehicles((prevVehicles) => {
        return prevVehicles.filter((vehicle) => vehicle.info.transferId !== vehicleId);
      });

      setResults((prevResults) => {
        return prevResults.filter((result) => result.id !== vehicleId);
      });
    }
  };

  return (
    <div style={{ display: "flex", width: "100%" }}>
      <Modal
        show={showModal}
        onCancel={() => setShowModal(false)}
        header="Uçuş transferleri oluşturulamadı."
        footerClass="place-item__modal-actions"
        footer={
          <React.Fragment>
            <Button inverse onClick={() => setShowModal(false)}>
              Tamam
            </Button>
          </React.Fragment>
        }
      >
        <p>{errorMessage}</p>
      </Modal>
      <div style={{ width: "100%" }}>
        <div style={{ marginBottom: "20px" }}>
          {transferCreated && (
            <div style={{ color: "red", fontWeight: "bold", marginBottom: "20px" }}>
              Bu uçuşun transferleri onaylanmıştır. Bu uçuş için değişikliklerinizi transferler sekmesinden
              yapabilirsiniz.
            </div>
          )}
          <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
            <div>
              <Button
                danger
                onClick={createTransfer}
                xs={{ alignSelf: "right" }}
                variant="contained"
                disabled={transferCreated || isTransferCreating}
              >
                Uçuştaki Transferleri Onayla
              </Button>
            </div>
            <div>{isTransferCreating && <LoadingSpinner />}</div>
          </div>
        </div>
        {vehicles.map((vehicle) => (
          <Vehicle
            key={vehicle.info.transferId + "-" + vehicle.info.transferName}
            id={vehicle.info.transferId}
            name={vehicle.info.transferName}
            airportTime={props.transfers.airportTime}
            people={vehicle.people}
            driver={vehicle.driver.driver}
            distances={props.transfers.distancesInformation}
            onDrop={handleDrop}
            transferType={props.transfers.routeType}
            onDeletePerson={(personId) => deletePersonHandler(vehicle.info.transferId, personId)}
            onChangeName={(newName) => changeVehicleName(vehicle.info.transferId, newName)}
            onPersonDown={(personId) => personDownHandler(vehicle.info.transferId, personId)}
            onPersonUp={(personId) => personUpHandler(vehicle.info.transferId, personId)}
            onOptimize={() => optimizeHandler(vehicle.info.transferId)}
            onDeleteVehicle={() => deleteVehicleHandler(vehicle.info.transferId)}
            onSaveResult={(calculatedPeople, driver, transferName) =>
              saveResultHandler(vehicle.info.transferId, calculatedPeople, driver, transferName)
            }
          />
        ))}
        <div style={{ marginBottom: "20px" }}>
          <Button danger onClick={addVehicleHandler} xs={{ alignSelf: "right" }} variant="contained">
            Uçuşa yeni transfer aracı ekle
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Dragger;
