import React, { useEffect, useState, useContext, useCallback, useRef } from "react";
import GoogleMapReact from "google-map-react";
import RoomIcon from "@mui/icons-material/Room";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

// #region Components
import Input from "shared/components/FormElements/Input";
import Button from "shared/components/FormElements/Button";
import CustomSelect from "shared/components/FormElements/Select";
import Card from "shared/components/UIElements/Card";
import LoadingSpinner from "shared/components/UIElements/LoadingSpinner";
import ErrorModal from "shared/components/UIElements/ErrorModal";
import Modal from "shared/components/UIElements/Modal";
import Marker from "shared/components/UIElements/Marker";
import DatePicker from "shared/components/FormElements/DatePicker";
import { formatDate } from "shared/util/time";
import TransferRouteItem from "./TransferRouteItem";

// #endregion Components

// #region Utils
import { transfertypes, transferStatus } from "shared/util/types";
import { VALIDATOR_REQUIRE } from "shared/util/validators";

import { GetStartStopTime, CheckTimeString } from "shared/util/time";
// #endregion Utils

// #region Hooks
import { useForm } from "shared/hooks/form-hook";
import { useHttpClient } from "shared/hooks/http-hook";
import { AuthContext } from "shared/context/auth-context";
import { StoreContext } from "shared/context/store-context";
// #endregion Hooks

import "./Item.css";

const TransferItem = (props) => {
  const auth = useContext(AuthContext);
  const store = useContext(StoreContext);
  const [currentPersonels, setCurrentPersonels] = useState([]);
  const [currentVehicleRoutes, setCurrentVehicleRoutes] = useState([]);
  const [currentVehicleRoutesDetailed, setCurrentVehicleRoutesDetailed] = useState([]);
  const [vehicleRouteOptions, setVehicleRouteOptions] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const [showNewRouteAddition, setShowNewRouteAddition] = useState(false);

  const [itemstate, setItemState] = useState();
  const [loadedItem, setLoadedItem] = useState();
  const [currentSmses, setCurrentSmses] = useState([]);

  const [view, setView] = useState(1);
  const alertMessageRef = useRef("");

  const itemId = props.itemid;
  const currentTime = props.currentTime;
  const updateStoreItems = store.UpdateTransfers;
  const updateVehicleRoutes = store.UpdateVehicleRoutes;

  const ItemTypeTR = "Transfer";
  const ItemType = "transfer";
  const ItemEndPointType = "transfers";

  // #region Standard Interface functions
  const { isLoading, error, sendRequest, clearError } = useHttpClient();

  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const showDeleteWarningHandler = () => {
    setShowConfirmModal(true);
  };

  const cancelDeleteHandler = () => {
    setShowConfirmModal(false);
  };
  // #endregion Standard Interface functions

  const [formState, inputHandler] = useForm(
    {
      school: { value: "", isValid: true },
      name: { value: "", isValid: true },
      type: { value: "", isValid: true },
      transferdate: { value: "", isValid: true },
      transfertime: { value: "", isValid: true },
      done: { value: false, isValid: true },
      vehicleRoutes: { value: [], isValid: true },
    },
    true
  );

  const [formStatePersonel, inputHandlerPersonel] = useForm(
    {
      personelUnique: { value: "", isValid: true },
    },
    true
  );

  const [formStateNewRoute, inputHandlerNewRoute] = useForm(
    {
      newvehicleroutename: { value: "", isValid: false },
      vehicleroutestarttime: { value: "", isValid: false },
      vehicleroutestoptime: { value: "", isValid: false },
      newvehicleroutedriver: { value: [], isValid: false },
      newvehiclerouteplate: { value: "", isValid: false },
      newvehicletransferregion: { value: "", isValid: false },
    },
    false
  );

  const addUniquePersonel = async (tey) => {
    try {
      if (!currentPersonels.includes(formStatePersonel.inputs.personelUnique.value)) {
        setCurrentPersonels([...currentPersonels, formStatePersonel.inputs.personelUnique.value]);
      }
    } catch (err) {}
  };

  const togglenewRouteAddition = () => {
    setShowNewRouteAddition(!showNewRouteAddition);
  };

  const addVehicleRoute = async () => {
    if (
      !CheckTimeString(formStateNewRoute.inputs.vehicleroutestoptime.value) ||
      !CheckTimeString(formStateNewRoute.inputs.vehicleroutestarttime.value)
    ) {
      alertMessageRef.current = "Saat bilgisini **:** formatında giriniz. Örnek: 07:00 ya da 18:35";
      setShowAlert(true);
      return;
    }

    //check driver array has at least 1 element
    if (formStateNewRoute.inputs.newvehicleroutedriver.value.length < 1) {
      alertMessageRef.current = "Servis için en az 1 sürücü seçiniz.";
      setShowAlert(true);
      return;
    }

    togglenewRouteAddition();

    try {
      const vehicleRouteData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/vehicleroutes`,
        "POST",
        JSON.stringify({
          name: formStateNewRoute.inputs.newvehicleroutename.value,
          type: formState.inputs.type.value,
          school: formState.inputs.school.value,
          driver: formStateNewRoute.inputs.newvehicleroutedriver.value,
          plate: formStateNewRoute.inputs.newvehiclerouteplate.value,
          starttime: formStateNewRoute.inputs.vehicleroutestarttime.value,
          stoptime: formStateNewRoute.inputs.vehicleroutestoptime.value,
          student: [],
          status: 1,
          location: { lat: -1.1, lng: -1.1 },
          routelocations: [
            {
              student: "2",
              transfertime: "1",
              transferregion: formStateNewRoute.inputs.newvehicletransferregion.value,
            },
          ],
        }),
        {
          "Content-Type": "application/json",
          Authorization: "Bearer " + auth.token,
        }
      );

      const newVehicleRouteId = vehicleRouteData.vehicleRoute._id.toString();

      //update transfer with new vehicle route id
      await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/transfers/${itemId}`,
        "PATCH",
        JSON.stringify({
          vehicleRoutes: [...currentVehicleRoutes, newVehicleRouteId],
        }),
        {
          "Content-Type": "application/json",
          Authorization: "Bearer " + auth.token,
        }
      );
    } catch (err) {}

    updateVehicleRoutes();
    updateStoreItems();
    await new Promise((resolve) => setTimeout(resolve, 7000));

    setItemState(Date.now());
  };

  const getFullPersonel = (personelId) => {
    try {
      //get personel from personel id in store
      const personel = store.students.find((personel) => personel.id === personelId);
      return personel;
    } catch (err) {}
  };

  const RemovePersonel = (personelId) => {
    try {
      const newPersonels = currentPersonels.filter((personel) => personel !== personelId);
      setCurrentPersonels(newPersonels);
    } catch (err) {}
  };

  const RemoveVehicleRoute = async (vehicleRouteId) => {
    try {
      //Update transfer with new vehicle route id
      await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/transfers/${itemId}`,
        "PATCH",
        JSON.stringify({
          vehicleRoutes: currentVehicleRoutes.filter((vehicleRoute) => vehicleRoute !== vehicleRouteId),
        }),
        {
          "Content-Type": "application/json",
          Authorization: "Bearer " + auth.token,
        }
      );

      updateVehicleRoutes();
      updateStoreItems();

      setItemState(Date.now());
    } catch (err) {}
  };

  //create a function to update a vehicleRoute
  // const updateVehicleRoute = async (vehicleRouteId) => {
  //   try {
  //     const vehicleRouteData = await sendRequest(
  //       `${process.env.REACT_APP_BACKEND_URL}/vehicleroutes/${vehicleRouteId}`,
  //       "PATCH",
  //       JSON.stringify({
  //         name: formStateNewRoute.inputs.newvehicleroutename.value,
  //         type: formState.inputs.type.value,
  //         school: formState.inputs.school.value,
  //         driver: formStateNewRoute.inputs.newvehicleroutedriver.value,
  //         plate: formStateNewRoute.inputs.newvehiclerouteplate.value,
  //         starttime: formStateNewRoute.inputs.vehicleroutestarttime.value,
  //         stoptime: formStateNewRoute.inputs.vehicleroutestoptime.value,
  //         student: [],
  //         status: 1,
  //         location: { lat: -1.1, lng: -1.1 },
  //         routelocations: [{ student: "2", transfertime: "1" }],
  //       }),
  //       {
  //         "Content-Type": "application/json",
  //         Authorization: "Bearer " + auth.token,
  //       }
  //     );
  //     updateVehicleRoutes();
  //     updateStoreItems();
  //   } catch (err) {}
  // };

  const getPhoneNumberofStudent = (studentId) => {
    try {
      const foundRelation = store.relations.find((relation) => relation.student.toString() === studentId.toString());
      if (foundRelation) {
        return store.users.find((user) => user.id.toString() === foundRelation.parent.toString()).phonenumber;
      } else {
        return "";
      }
    } catch (err) {
      return "";
    }
  };

  const SendSMS = async (studentId) => {
    const message = document.getElementById("text" + studentId).value.trim();

    const foundRelation = store.relations.find((relation) => relation.student.toString() === studentId.toString());

    if (!foundRelation) {
      alert("Kişi için bildirim tanımlanmamış.");
      return;
    }

    const foundPhone = store.users.find((user) => user.id.toString() === foundRelation.parent.toString()).phonenumber;

    const user = foundRelation.parent.toString();
    const student = studentId.toString();
    const transfer = itemId.toString();
    const phone = foundPhone;
    const text = message;
    const date = formatDate(new Date());
    const status = 1;

    const smsData = { user, student, phone, text, date, status, transfer };

    try {
      await sendRequest(`${process.env.REACT_APP_BACKEND_URL}/dfhldfjhdrgda`, "POST", JSON.stringify(smsData), {
        "Content-Type": "application/json",
        Authorization: "Bearer " + auth.token,
      });
    } catch (err) {}

    await loadSMS();
  };

  const SendSMSToDriver = async (driver) => {
    const message = document.getElementById("text" + driver.id).value.trim();

    const foundPhone = driver.phonenumber;

    const user = driver.id.toString();
    const student = "619c64be5d123200169418c8";
    const transfer = itemId.toString();
    const phone = foundPhone;
    const text = message;
    const date = formatDate(new Date());
    const status = 1;

    const smsData = { user, student, phone, text, date, status, transfer };

    try {
      await sendRequest(`${process.env.REACT_APP_BACKEND_URL}/dfhldfjhdrgda`, "POST", JSON.stringify(smsData), {
        "Content-Type": "application/json",
        Authorization: "Bearer " + auth.token,
      });
    } catch (err) {}

    await loadSMS();
  };

  const loadSMS = useCallback(async () => {
    try {
      const responseData = await sendRequest(`${process.env.REACT_APP_BACKEND_URL}/dfhldfjhdrgda/${itemId}`, "POST");
      setCurrentSmses(responseData.smses);
    } catch (err) {}
  }, [sendRequest, itemId]);

  // #region Standard API calls

  const fetchItem = useCallback(async () => {
    try {
      const responseData = await sendRequest(`${process.env.REACT_APP_BACKEND_URL}/${ItemEndPointType}/${itemId}`);

      setCurrentVehicleRoutes(responseData[ItemType].vehicleRoutes);

      //Get vehicleRoute information from store and keep in a state
      const vehicleRoutes = [];
      const allStudents = [];
      for (let i = 0; i < responseData[ItemType].vehicleRoutes.length; i++) {
        const vehicleRoute = store.vehicleRoutes.find(
          (vehicleRoute) => vehicleRoute.id === responseData[ItemType].vehicleRoutes[i]
        );

        //get student from vehicleRoute and push it to an array
        const students = [];
        for (let j = 0; j < vehicleRoute.student.length; j++) {
          let studentid = "";
          if (vehicleRoute.student[j].id) {
            studentid = store.students.find((student) => student.id === vehicleRoute.student[j].id).id;
          } else {
            studentid = store.students.find((student) => student.id === vehicleRoute.student[j]).id;
          }

          // const student = store.students.find(
          //   (student) => student.id === vehicleRoute.student[j].id
          // );
          students.push(studentid);
          allStudents.push(studentid);
        }

        vehicleRoute.student = students;
        vehicleRoutes.push(vehicleRoute);
      }

      setCurrentVehicleRoutesDetailed(vehicleRoutes);

      const vehiclerouteoptions = vehicleRoutes.map((vehicleroute) => {
        return {
          value: vehicleroute.id,
          label: vehicleroute.name,
        };
      });
      setVehicleRouteOptions(vehiclerouteoptions);
      setCurrentPersonels(allStudents);
      loadSMS();
      setLoadedItem(responseData[ItemType]);
      //setItemState(Date.now());
    } catch (err) {
      console.log(err);
    }
  }, [itemId, loadSMS, sendRequest, store.students, store.vehicleRoutes]);

  useEffect(() => {
    fetchItem();
  }, [itemId, itemstate, currentTime, fetchItem]);

  const confirmDeleteHandler = async () => {
    setShowConfirmModal(false);
    try {
      await sendRequest(`${process.env.REACT_APP_BACKEND_URL}/${ItemEndPointType}/${itemId}`, "DELETE", null, {
        "Content-Type": "application/json",
      });
      setItemState(Date.now());
      updateStoreItems();
      setLoadedItem(null);
    } catch (err) {}
  };
  // #endregion Standard API calls

  useEffect(() => {}, [formState.inputs.personel]);

  //save all personel to a state
  const SaveAllPersonelToTransfer = async () => {
    const vehicleStudentsTimes = [];

    for (let i = 0; i < currentPersonels.length; i++) {
      const personelId = currentPersonels[i];
      const currentPersonel = getFullPersonel(personelId);

      const currentPersonelTransferTime = document.getElementById("personeltransfertime" + personelId).value;
      const vehicleText = document.getElementById("personelservice" + personelId).textContent;
      const currentPersonelVehicleId = vehicleRouteOptions.find((option) => option.label === vehicleText).value;

      vehicleStudentsTimes.push({
        personelId: currentPersonel.id,
        vehicleId: currentPersonelVehicleId,
        transfertime: currentPersonelTransferTime,
      });
    }

    const tempCurrentVehicleRoutesDetailed = [...currentVehicleRoutesDetailed];
    //add vehicleStudentsTimes to currentVehicleRoutesDetailed
    for (let i = 0; i < tempCurrentVehicleRoutesDetailed.length; i++) {
      const currentTransferRegion = tempCurrentVehicleRoutesDetailed[i].routelocations[0].transferregion;
      tempCurrentVehicleRoutesDetailed[i].student = [];
      tempCurrentVehicleRoutesDetailed[i].routelocations = [
        { student: "2", transfertime: "1", transferregion: currentTransferRegion },
      ];
      for (let j = 0; j < vehicleStudentsTimes.length; j++) {
        if (tempCurrentVehicleRoutesDetailed[i].id === vehicleStudentsTimes[j].vehicleId) {
          tempCurrentVehicleRoutesDetailed[i].student.push(vehicleStudentsTimes[j].personelId);
          tempCurrentVehicleRoutesDetailed[i].routelocations.push({
            student: vehicleStudentsTimes[j].personelId,
            transfertime: vehicleStudentsTimes[j].transfertime,
            transferregion: currentTransferRegion,
          });
        }
      }

      try {
        await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/vehicleroutes/${tempCurrentVehicleRoutesDetailed[i].id}`,
          "PATCH",
          JSON.stringify({
            student: tempCurrentVehicleRoutesDetailed[i].student,
            routelocations: tempCurrentVehicleRoutesDetailed[i].routelocations,
          }),
          {
            "Content-Type": "application/json",
            Authorization: "Bearer " + auth.token,
          }
        );
      } catch (err) {}
    }

    setCurrentVehicleRoutesDetailed(tempCurrentVehicleRoutesDetailed);
  };

  const GetTransferTimeofStudent = (studentId) => {
    const foundVehicleRoute = currentVehicleRoutesDetailed.find((vehicleRoute) =>
      vehicleRoute.student.includes(studentId)
    );

    if (foundVehicleRoute) {
      const transfertime = foundVehicleRoute.routelocations.find(
        (routelocation) => routelocation.student === studentId
      );

      if (transfertime) {
        return transfertime.transfertime;
      }
    }
    return "";
  };

  const GetVehicleRouteofStudent = (studentId) => {
    const foundVehicleRoute = currentVehicleRoutesDetailed.find((vehicleRoute) =>
      vehicleRoute.student.includes(studentId)
    );
    if (foundVehicleRoute) {
      return vehicleRouteOptions.find((option) => option.value === foundVehicleRoute.id);
    }
    return null;
  };

  const itemUpdateSubmitHandler = useCallback(
    async (event) => {
      event.preventDefault();

      if (!CheckTimeString(formState.inputs.transfertime.value)) {
        alertMessageRef.current =
          "Havalimanında olma saati bilgisini **:** formatında giriniz. Örnek: 07:00 ya da 18:35";
        setShowAlert(true);
        return;
      }

      let responseData;
      try {
        responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/${ItemEndPointType}/${itemId}`,
          "PATCH",
          JSON.stringify({
            name: formState.inputs.name.value,
            type: formState.inputs.type.value,
            school: formState.inputs.school.value,
            transferdate: formState.inputs.transferdate.value,
            transfertime: formState.inputs.transfertime.value,
            done: formState.inputs.done.value,
            //vehicleRoutes: currentVehicleRoutes,
          }),
          {
            "Content-Type": "application/json",
            Authorization: "Bearer " + auth.token,
          }
        );

        setLoadedItem(responseData[ItemType]);
        updateStoreItems();
        setItemState(Date.now());
      } catch (err) {}
    },
    [sendRequest, auth.token, formState, itemId, updateStoreItems]
  );

  const GotoView = (view) => {
    setView(view);
  };

  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setShowAlert(false);
  };

  if (isLoading) {
    return (
      <div className="center">
        <LoadingSpinner />
      </div>
    );
  }

  if (!loadedItem && !error) {
    return (
      <div className="center">
        <Card>
          <h2>{ItemTypeTR} bulunamadı.</h2>
        </Card>
      </div>
    );
  }

  return (
    <React.Fragment>
      <ErrorModal error={error} onClear={clearError} />
      <Modal
        show={showConfirmModal}
        onCancel={cancelDeleteHandler}
        header="Emin misiniz?"
        footerClass="place-item__modal-actions"
        footer={
          <React.Fragment>
            <Button inverse onClick={cancelDeleteHandler}>
              Hayır
            </Button>
            <Button danger onClick={confirmDeleteHandler}>
              Evet
            </Button>
          </React.Fragment>
        }
      >
        <p>{ItemTypeTR} silme işlemini onaylıyor musunuz? Bu işlem geri alınamaz.</p>
      </Modal>

      <Snackbar
        open={showAlert}
        autoHideDuration={2000}
        onClose={handleAlertClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <MuiAlert onClose={handleAlertClose} severity="error" sx={{ width: "100%" }}>
          {alertMessageRef.current}
        </MuiAlert>
      </Snackbar>

      {!isLoading && loadedItem && (
        <div>
          <div className="header-buttons-container">
            <Button type="button" danger style={{ marginRight: "20px" }} onClick={props.onClose}>
              &#60; Geri
            </Button>

            <div style={{ display: "flex", flexDirection: "row", columnGap: "20px" }}>
              <Button type="button" primary onClick={() => GotoView(1)}>
                Operasyon Bilgileri
              </Button>

              <Button type="button" primary onClick={() => GotoView(2)}>
                Personel Bilgileri
              </Button>

              <Button type="button" primary onClick={() => GotoView(3)}>
                Servis Bilgileri
              </Button>
            </div>
          </div>

          {view === 1 && (
            <form key={itemstate} className="item-form" onSubmit={itemUpdateSubmitHandler}>
              <CustomSelect
                options={store.schoolOptions}
                id="school"
                onInput={inputHandler}
                label="Kurum"
                initialValue={store.schoolOptions.find(
                  (school) => school.value.toString() === loadedItem.school.toString()
                )}
                validators={[VALIDATOR_REQUIRE()]}
                initialValid={true}
              ></CustomSelect>

              <Input
                id="name"
                element="input"
                type="text"
                label="Transfer İsmi"
                validators={[VALIDATOR_REQUIRE()]}
                errorText="Transfer ismi giriniz."
                onInput={inputHandler}
                initialValue={loadedItem.name}
                initialValid={true}
              />

              <CustomSelect
                options={transfertypes}
                id="type"
                onInput={inputHandler}
                label="Transfer Tipi"
                initialValue={transfertypes.find((type) => type.value.toString() === loadedItem.type.toString())}
                validators={[VALIDATOR_REQUIRE()]}
                initialValid={true}
              ></CustomSelect>

              <DatePicker
                id="transferdate"
                onInput={inputHandler}
                label="Transfer Tarihi"
                initialValue={loadedItem.transferdate}
                initialValid={true}
              ></DatePicker>

              <Input
                id="transfertime"
                element="input"
                type="text"
                label="Havalimanında olma saati"
                validators={[VALIDATOR_REQUIRE()]}
                errorText="**:** formatında giriniz. Örnek: 07:00 ya da 18:35"
                onInput={inputHandler}
                initialValue={GetStartStopTime(loadedItem.transfertime)}
                initialValid={true}
              />

              {/* <CustomSelect
                options={store.vehicleRouteOptions}
                id="vehicleRoutes"
                isMulti={true}
                onInput={inputHandler}
                label="Servisler"
                initialValue={store.vehicleRouteOptions.filter((option) =>
                  loadedItem.vehicleRoutes.includes(option.value)
                )}
                initialValid={true}
              ></CustomSelect> */}

              <CustomSelect
                options={transferStatus}
                id="done"
                onInput={inputHandler}
                label="İş Durumu"
                initialValue={transferStatus.find((status) => status.value.toString() === loadedItem.done.toString())}
                validators={[VALIDATOR_REQUIRE()]}
                initialValid={true}
              ></CustomSelect>

              <div className="button-container">
                <Button type="submit" disabled={!formState.isValid}>
                  Güncelle
                </Button>

                <Button type="button" danger onClick={showDeleteWarningHandler}>
                  SİL
                </Button>
              </div>
            </form>
          )}

          {view === 2 && (
            <div className="item-form">
              <CustomSelect
                options={store.studentOptions}
                id="personelUnique"
                isMulti={false}
                label="Personel"
                initialValid={true}
                onInput={inputHandlerPersonel}
              ></CustomSelect>

              <Button type="button" danger onClick={addUniquePersonel.bind(this, "apply")}>
                Personel Ekle
              </Button>

              <TableContainer
                component={Paper}
                sx={{
                  minWidth: 650,
                  marginTop: "20px",
                  paddingBottom: "50px",
                  marginBottom: "20px",
                }}
              >
                <Table
                  sx={{
                    minWidth: 650,
                    width: "100%",
                    justifyContent: "center",
                    margin: "auto",
                    zIndex: -1,
                  }}
                  aria-label="simple table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell width="5%"></TableCell>
                      <TableCell width="10%">
                        <b>Personel</b>
                      </TableCell>
                      <TableCell width="20%">
                        <b>Rota</b>
                      </TableCell>
                      <TableCell width="10%">
                        <b>Alınış Saati</b>
                      </TableCell>
                      <TableCell width="30%">
                        <b>Giden Mesajlar</b>
                      </TableCell>
                      <TableCell width="25%" align="right">
                        <b>Yeni Mesaj (HER MESAJ EN FAZLA 155 KARAKTER)</b>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {currentPersonels.map((personelId) => (
                      <TableRow
                        key={"PersonelRow" + personelId}
                        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                      >
                        <TableCell component="th" scope="row">
                          <Button type="button" danger onClick={() => RemovePersonel(personelId)}>
                            Sil
                          </Button>
                        </TableCell>

                        <TableCell component="th" scope="row">
                          {getFullPersonel(personelId).name} <br /> {getPhoneNumberofStudent(personelId)}
                        </TableCell>

                        <TableCell component="th" scope="row">
                          {/* //YEHu */}
                          <CustomSelect
                            id={"personelservice" + personelId}
                            options={vehicleRouteOptions}
                            isMulti={false}
                            onInput={inputHandlerPersonel}
                            initialValid={false}
                            initialValue={GetVehicleRouteofStudent(personelId)}
                            validators={[VALIDATOR_REQUIRE()]}
                            errorText="Personel için servis seçiniz."
                          />
                        </TableCell>

                        <TableCell component="th" scope="row">
                          <Input
                            id={"personeltransfertime" + personelId}
                            element="input"
                            type="text"
                            onInput={inputHandlerPersonel}
                            initialValid={true}
                            initialValue={GetTransferTimeofStudent(personelId)}
                            validators={[VALIDATOR_REQUIRE()]}
                            errorText="**:** formatında giriniz. Örnek: 07:00 ya da 18:35"
                          />
                        </TableCell>

                        <TableCell component="th" scope="row">
                          {currentSmses.filter((sms) => sms.student.id.toString() === personelId.toString()).length >
                          0 ? (
                            <div>
                              {currentSmses
                                .filter((sms) => sms.student.id.toString() === personelId.toString())
                                .map((sms) => (
                                  <div key={sms.id}>
                                    {sms.text}
                                    <br />
                                    <i>{sms.date}</i>
                                    <br />
                                    <br />
                                  </div>
                                ))}
                            </div>
                          ) : (
                            <div>Mesaj Yok</div>
                          )}
                        </TableCell>

                        <TableCell scope="row" align="right">
                          <div style={{ display: "flex", flexDirection: "column" }}>
                            <textarea
                              style={{ margin: "16px" }}
                              key={"SMS" + personelId}
                              id={"text" + personelId.toString().trim()}
                              cols="30"
                              rows="7"
                              maxLength="155"
                              defaultValue={`Your pickup time for flight on ${
                                loadedItem.transferdate
                              } will be at ${GetTransferTimeofStudent(
                                personelId
                              )}. For further info Contact 0549 8003931.`}
                            ></textarea>
                            <div width="60px">
                              <Button type="button" danger onClick={() => SendSMS(personelId.toString().trim())}>
                                SMS Gönder
                              </Button>
                            </div>
                          </div>
                        </TableCell>
                      </TableRow>
                    ))}

                    {/* {currentPersonels.map((personelId) => (
                      <TableRow
                        key={"PersonelRow" + personelId}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        <TableCell width="30px" component="th" scope="row">
                          <Button type="button" danger onClick={() => RemovePersonel(personelId)}>
                            Sil
                          </Button>
                        </TableCell>

                        <TableCell component="th" scope="row">
                          {personelId}
                        </TableCell>
                        <TableCell scope="row" align="right">
                          {getPhoneNumberofStudent(personelId)}
                        </TableCell>
                      </TableRow>
                    ))} */}
                  </TableBody>
                </Table>
              </TableContainer>

              {currentPersonels.length > 0 && (
                <div className="map-container">
                  <GoogleMapReact
                    bootstrapURLKeys={{
                      key: "AIzaSyAkGOMUyxOH98l6qetwEChfJDgzMrBiDbc",
                      language: "tr",
                      region: "tr",
                      libraries: ["drawing"],
                    }}
                    options={function (maps) {
                      return {
                        mapTypeControl: true,
                        mapTypeId: "satellite",
                        mapTypeControlOptions: {
                          mapTypeIds: ["satellite", "roadmap"],
                          style: maps.MapTypeControlStyle.HORIZONTAL_BAR,
                          position: maps.ControlPosition.TOP_LEFT,
                        },
                      };
                    }}
                    defaultCenter={{ lat: 36.8969, lng: 30.7133 }}
                    // center={
                    //   currentPersonels[0]
                    //     ? currentPersonels[0].location
                    //     : { lat: 36.8969, lng: 30.7133 }
                    // }
                    defaultZoom={11}
                  >
                    {currentPersonels.map((student) => {
                      return (
                        <Marker
                          Icon={RoomIcon}
                          lat={
                            store.students.find((item) => item.id.toString() === student.toString()).locationgeton.lat
                          }
                          lng={
                            store.students.find((item) => item.id.toString() === student.toString()).locationgeton.lng
                          }
                          key={student}
                          info={store.students.find((item) => item.id.toString() === student.toString()).name}
                        />
                      );
                    })}
                  </GoogleMapReact>
                </div>
              )}

              <Button type="button" danger onClick={SaveAllPersonelToTransfer.bind(this, "apply")}>
                Kaydet
              </Button>
            </div>
          )}

          {view === 3 && (
            <div className="item-form">
              <Button type="button" danger o onClick={togglenewRouteAddition}>
                {showNewRouteAddition ? "Rota Eklemeyi Kapat" : "Yeni Rota Ekle"}
              </Button>

              {showNewRouteAddition && (
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                  <div className="groupContainer">
                    <Input
                      id="newvehicleroutename"
                      element="input"
                      type="text"
                      label="Şoför Ekranında Görünecek Servis İsmi"
                      validators={[VALIDATOR_REQUIRE()]}
                      errorText="Servis ismi boş bırakılamaz."
                      onInput={inputHandlerNewRoute}
                      // initialValue={GetStartStopTime(loadedItem.stoptime)}
                      initialValid={false}
                    />

                    <CustomSelect
                      options={store.transferregionOptions}
                      id="newvehicletransferregion"
                      isMulti={false}
                      onInput={inputHandlerNewRoute}
                      label="Transfer Bölgesi"
                      initialValid={true}
                    ></CustomSelect>

                    <CustomSelect
                      options={store.driverOptions}
                      id="newvehicleroutedriver"
                      isMulti={true}
                      onInput={inputHandlerNewRoute}
                      label="Rotaya Yetkisi Olan Sürücüler"
                      errorText="En az 1 sürücü seçiniz."
                      // initialValue={store.driverOptions.filter((option) => loadedItem.driver.includes(option.value))}
                      initialValid={false}
                      validators={[VALIDATOR_REQUIRE()]}
                    ></CustomSelect>

                    <Input
                      id="newvehiclerouteplate"
                      element="input"
                      type="text"
                      label="Servis plakası"
                      validators={[VALIDATOR_REQUIRE()]}
                      errorText="Servis plakasını giriniz."
                      onInput={inputHandlerNewRoute}
                      // initialValue={loadedItem.plate}
                      initialValid={false}
                    />

                    <Input
                      id="vehicleroutestarttime"
                      element="input"
                      type="text"
                      label="Servis İzleme Başlama Saati"
                      validators={[VALIDATOR_REQUIRE()]}
                      errorText="**:** formatında giriniz. Örnek: 07:00 ya da 18:35"
                      onInput={inputHandlerNewRoute}
                      // initialValue={GetStartStopTime(loadedItem.starttime)}
                      initialValid={false}
                    />
                    <Input
                      id="vehicleroutestoptime"
                      element="input"
                      type="text"
                      label="Servis İzleme Bitiş Saati"
                      validators={[VALIDATOR_REQUIRE()]}
                      errorText="**:** formatında giriniz. Örnek: 07:00 ya da 18:35"
                      onInput={inputHandlerNewRoute}
                      // initialValue={GetStartStopTime(loadedItem.stoptime)}
                      initialValid={false}
                    />

                    <Button
                      type="button"
                      danger
                      onClick={addVehicleRoute.bind(this, "apply")}
                      disabled={!formStateNewRoute.isValid}
                    >
                      Operasyona Servis Ekle
                    </Button>
                  </div>
                </div>
              )}

              <div style={{ borderBottom: "2px solid red", width: "100%", marginTop: "30px" }}></div>

              <TableContainer
                component={Paper}
                sx={{
                  minWidth: 650,
                  marginTop: "20px",
                  paddingBottom: "50px",
                  marginBottom: "20px",
                }}
              >
                <Table
                  sx={{
                    minWidth: 650,
                    width: "100%",
                    justifyContent: "center",
                    margin: "auto",
                    zIndex: -1,
                  }}
                  aria-label="simple table"
                >
                  <TableBody>
                    {currentVehicleRoutesDetailed.map((vehicleRoute) => (
                      <React.Fragment key={"FragmentList" + vehicleRoute.id}>
                        <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                            <TransferRouteItem
                              itemid={vehicleRoute.id}
                              onDelete={() => RemoveVehicleRoute(vehicleRoute.id)}
                            />
                          </TableCell>
                        </TableRow>

                        <TableRow
                          sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                          key={"DriverRow" + vehicleRoute.driver[0].id}
                        >
                          <TableCell component="th" scope="row">
                            <b>Sürücü İletişim</b>
                          </TableCell>

                          <TableCell component="th" scope="row">
                            {vehicleRoute.driver[0].name} <br /> {vehicleRoute.driver[0].phonenumber}
                          </TableCell>

                          {/* <TableCell component="th" scope="row">
                          <Input
                            id={'driverfirstpersonetime' + driver.id}
                            element="input"
                            type="text"
                            onInput={inputHandlerPersonel}
                            initialValid={true}
                            initialValue={GetTransferTimeofStudent(personelId)}
                            validators={[VALIDATOR_REQUIRE()]}
                            errorText="**:** formatında giriniz. Örnek: 07:00 ya da 18:35"
                          />
                        </TableCell> */}

                          <TableCell component="th" scope="row">
                            {currentSmses.filter(
                              (sms) => sms.user.id.toString() === vehicleRoute.driver[0].id.toString()
                            ).length > 0 ? (
                              <div>
                                {currentSmses
                                  .filter((sms) => sms.user.id.toString() === vehicleRoute.driver[0].id.toString())
                                  .map((sms) => (
                                    <div key={sms.id}>
                                      {sms.text}
                                      <br />
                                      <i>{sms.date}</i>
                                      <br />
                                      <br />
                                    </div>
                                  ))}
                              </div>
                            ) : (
                              <div>Mesaj Yok</div>
                            )}
                          </TableCell>

                          <TableCell scope="row" align="right" width="30%">
                            <div style={{ display: "flex", flexDirection: "column" }}>
                              <textarea
                                style={{ margin: "16px" }}
                                key={"SMS" + vehicleRoute.driver[0].id}
                                id={"text" + vehicleRoute.driver[0].id.toString().trim()}
                                cols="30"
                                rows="7"
                                maxLength="155"
                                defaultValue={`Yeni servis operasyonu.\nServis: ${vehicleRoute.name}\nTarih: ${
                                  loadedItem.transferdate
                                }\nSaat: ${GetStartStopTime(vehicleRoute.starttime)}\nOperasyon Tipi: ${
                                  loadedItem.type === 100 ? "Toplama" : "Dağıtma"
                                }`}
                              ></textarea>
                              <div width="60px">
                                <Button type="button" danger onClick={() => SendSMSToDriver(vehicleRoute.driver[0])}>
                                  SMS Gönder
                                </Button>
                              </div>
                            </div>
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell component="th" scope="row" colSpan={6}>
                            {/* div with border bottom */}
                            <div style={{ borderBottom: "2px solid red", width: "100%" }}></div>
                          </TableCell>
                        </TableRow>
                      </React.Fragment>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          )}
        </div>
      )}
    </React.Fragment>
  );
};

export default TransferItem;
