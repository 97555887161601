import React, { useEffect, useState, useContext, useCallback } from "react";
import { useHistory } from "react-router-dom";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

// #region Components
import Input from "shared/components/FormElements/Input";
import CustomSelect from "shared/components/FormElements/Select";
import Button from "shared/components/FormElements/Button";
import Card from "shared/components/UIElements/Card";
import LoadingSpinner from "shared/components/UIElements/LoadingSpinner";
import ErrorModal from "shared/components/UIElements/ErrorModal";
import Modal from "shared/components/UIElements/Modal";
// #endregion Components

// #region Utils
import { VALIDATOR_REQUIRE, VALIDATOR_PHONENUMBER, VALIDATOR_NONE } from "shared/util/validators";
import { LocationFromAddress } from "shared/util/location";
// #endregion Utils

// #region Hooks
import { useForm } from "shared/hooks/form-hook";
import { useHttpClient } from "shared/hooks/http-hook";
import { AuthContext } from "shared/context/auth-context";
import { StoreContext } from "shared/context/store-context";
// #endregion Hooks

import "./Item.css";

const TransferItem = (props) => {
  const auth = useContext(AuthContext);
  const store = useContext(StoreContext);

  const [itemstate, setItemState] = useState();
  const [loadedItem, setLoadedItem] = useState();
  const [openError, setOpenError] = React.useState(false);
  const [markerlocation, setMarkerLocation] = useState();

  const itemId = props.itemid;
  const updateStoreItems = store.UpdateIkarusTransfers;

  const ItemTypeTR = "İkarus Transfer";
  const ItemType = "transfer";
  const ItemEndPointType = "ikarustransfers";

  // #region Standard Interface functions
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const history = useHistory();

  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const cancelDeleteHandler = () => {
    setShowConfirmModal(false);
  };
  // #endregion Standard Interface functions

  // #region Standard API calls
  const fetchItem = useCallback(async () => {
    try {
      const responseData = await sendRequest(`${process.env.REACT_APP_BACKEND_URL}/${ItemEndPointType}/${itemId}`);
      setLoadedItem(responseData[ItemType]);
      //setMarkerLocation(responseData[ItemType].location);
      setItemState(Date.now());
    } catch (err) {}
  }, [sendRequest, itemId]);

  const confirmDeleteHandler = async () => {
    setShowConfirmModal(false);
    try {
      await sendRequest(`${process.env.REACT_APP_BACKEND_URL}/${ItemEndPointType}/${itemId}`, "DELETE", null, {
        "Content-Type": "application/json",
      });
      setItemState(Date.now());
      updateStoreItems();
      setLoadedItem(null);
      history.push(`/${ItemEndPointType}`);
    } catch (err) {}
  };
  // #endregion Standard API calls

  useEffect(() => {
    fetchItem();
  }, [itemId, fetchItem]);

  const [formState, inputHandler] = useForm(
    {
      name: { value: "", isValid: true },
      type: { value: "", isValid: true },
      orderid: { value: "", isValid: true },
      phonenumber: { value: "", isValid: true },
      email: { value: "", isValid: true },
      country: { value: "", isValid: true },
      city: { value: "", isValid: true },
      address: { value: "", isValid: true },
      isVip: { value: "", isValid: true },
      transferdate: { value: "", isValid: true },
      transfertime: { value: "", isValid: true },
      from_location: { value: "", isValid: true },
      to_location: { value: "", isValid: true },
      from_address: { value: "", isValid: true },
      to_address: { value: "", isValid: true },
      from_lat: { value: "", isValid: true },
      from_lng: { value: "", isValid: true },
      to_lat: { value: "", isValid: true },
      to_lng: { value: "", isValid: true },
      passengers: { value: [], isValid: true },
      userIp: { value: "", isValid: true },
      amount: { value: "", isValid: true },
      paid: { value: "", isValid: true },
      installmentCount: { value: "", isValid: true },
      note: { value: "", isValid: true },
      driver: { value: "", isValid: true },
      vehicleRoute: { value: "", isValid: true },
    },
    true
  );

  const itemUpdateSubmitHandler = useCallback(
    async (event) => {
      event.preventDefault();
      let responseData;
      try {
        responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/${ItemEndPointType}/${itemId}`,
          "PATCH",
          JSON.stringify({
            name: formState.inputs.name.value,
            type: formState.inputs.type.value,
            useqrcode: formState.inputs.useqrcode.value,
            phonenumber: formState.inputs.phonenumber.value,
            email: formState.inputs.email.value,
            address: formState.inputs.address.value,
            location: markerlocation,
            distance: formState.inputs.distance.value,
          }),
          {
            "Content-Type": "application/json",
            Authorization: "Bearer " + auth.token,
          }
        );

        setLoadedItem(responseData[ItemType]);
        setItemState(Date.now());
        updateStoreItems();

        history.push(`/${ItemEndPointType}`);
      } catch (err) {}
    },
    [auth.token, sendRequest, itemId, formState.inputs, markerlocation, history, updateStoreItems]
  );

  if (isLoading) {
    return (
      <div className="center">
        <LoadingSpinner />
      </div>
    );
  }

  if (!loadedItem && !error) {
    return (
      <div className="center">
        <Card>
          <h2>{ItemTypeTR} bulunamadı.</h2>
        </Card>
      </div>
    );
  }

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenError(false);
  };

  //eslint-disable-next-line
  const SetLocationFromAddress = async (event) => {
    let location;
    try {
      location = await LocationFromAddress(formState.inputs.address.value);
    } catch (error) {
      setMarkerLocation(null);
      setOpenError(true);
      return;
    }
    setMarkerLocation(location);
  };

  return (
    <React.Fragment>
      <ErrorModal error={error} onClear={clearError} />
      <Modal
        show={showConfirmModal}
        onCancel={cancelDeleteHandler}
        header="Emin misiniz?"
        footerClass="place-item__modal-actions"
        footer={
          <React.Fragment>
            <Button inverse onClick={cancelDeleteHandler}>
              Hayır
            </Button>
            <Button danger onClick={confirmDeleteHandler}>
              Evet
            </Button>
          </React.Fragment>
        }
      >
        <p>{ItemTypeTR} silme işlemini onaylıyor musunuz? Bu işlem geri alınamaz.</p>
      </Modal>

      <Snackbar
        open={openError}
        autoHideDuration={2000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <MuiAlert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          Adresten konum bulunamadı.
        </MuiAlert>
      </Snackbar>

      {!isLoading && loadedItem && (
        <form key={itemstate} className="item-form" onSubmit={itemUpdateSubmitHandler}>
          <div className="button-container">
            <Button type="button" danger onClick={props.onClose}>
              &#60; Geri
            </Button>
          </div>
          <Input
            id="orderid"
            element="input"
            type="text"
            label="Sipariş Numarası"
            validators={[VALIDATOR_REQUIRE()]}
            errorText="Sipariş numarası giriniz."
            initialValue={loadedItem.orderid}
            initialValid={true}
            onInput={inputHandler}
            disabled
          />
          <Input
            id="transferdate"
            element="input"
            type="text"
            label="Tarih"
            validators={[VALIDATOR_REQUIRE()]}
            errorText="Tarih giriniz."
            initialValue={loadedItem.transferdate}
            initialValid={true}
            onInput={inputHandler}
            disabled
          />
          <Input
            id="transfertime"
            element="input"
            type="text"
            label="Saat"
            validators={[VALIDATOR_REQUIRE()]}
            errorText="Saat giriniz."
            initialValue={loadedItem.transfertime}
            initialValid={true}
            onInput={inputHandler}
            disabled
          />
          <Input
            id="name"
            element="input"
            type="text"
            label="İsim"
            validators={[VALIDATOR_REQUIRE()]}
            errorText="İsim giriniz."
            initialValue={loadedItem.name}
            initialValid={true}
            onInput={inputHandler}
            disabled
          />
          <Input
            id="phonenumber"
            element="input"
            type="text"
            label="Telefon Numarası"
            validators={[VALIDATOR_REQUIRE(), VALIDATOR_PHONENUMBER()]}
            errorText="Telefon numarası giriniz."
            initialValue={loadedItem.phonenumber}
            initialValid={true}
            onInput={inputHandler}
            disabled
          />
          <Input
            id="email"
            element="input"
            type="text"
            label="E-Posta"
            validators={[VALIDATOR_REQUIRE()]}
            errorText="E-Posta giriniz."
            initialValue={loadedItem.email}
            initialValid={true}
            onInput={inputHandler}
            disabled
          />
          <Input
            id="region"
            element="input"
            type="text"
            label="Bölge"
            validators={[VALIDATOR_REQUIRE()]}
            errorText="Bölge giriniz."
            initialValue={
              loadedItem.from_location.includes("ANTALYA HAVALİMANI")
                ? loadedItem.to_location
                : loadedItem.from_location
            }
            initialValid={true}
            onInput={inputHandler}
            disabled
          />
          <Input
            id="from_address"
            element="input"
            type="text"
            label="Biniş adresi"
            validators={[VALIDATOR_REQUIRE()]}
            errorText="Biniş adresini giriniz."
            initialValue={loadedItem.from_address}
            initialValid={true}
            onInput={inputHandler}
            disabled
          />
          <Input
            id="to_address"
            element="input"
            type="text"
            label="İniş adresi"
            validators={[VALIDATOR_REQUIRE()]}
            errorText="İniş adresini giriniz."
            initialValue={loadedItem.to_address}
            initialValid={true}
            onInput={inputHandler}
            disabled
          />
          <Input
            id="passengers"
            element="input"
            type="text"
            label="Yolcu Sayısı"
            validators={[VALIDATOR_REQUIRE()]}
            errorText="Yolcu sayısı giriniz."
            initialValue={loadedItem.passengers.length}
            initialValid={true}
            onInput={inputHandler}
            disabled
          />
          <CustomSelect
            id="isVip"
            element="select"
            label="VIP"
            validators={[VALIDATOR_NONE()]}
            errorText="VIP seçiniz."
            initialValue={loadedItem.isVip ? { value: true, label: "Evet" } : { value: false, label: "Hayır" }}
            initialValid={true}
            onInput={inputHandler}
            options={[
              { value: true, label: "Evet" },
              { value: false, label: "Hayır" },
            ]}
            disabled
          />
          <Input
            id="note"
            element="textarea"
            type="text"
            label="Yolcu Notu"
            validators={[VALIDATOR_NONE()]}
            errorText="Not giriniz."
            initialValue={loadedItem.note}
            initialValid={true}
            onInput={inputHandler}
            disabled
          />
          <Input
            id="amount"
            element="input"
            type="text"
            label="Tutar"
            validators={[VALIDATOR_REQUIRE()]}
            errorText="Tutar giriniz."
            initialValue={loadedItem.amount}
            initialValid={true}
            onInput={inputHandler}
            disabled
          />
          <div className="horizontal-line" />
          Fatura Bilgileri
          <Input
            id="passengername"
            element="input"
            type="text"
            label="Fatura İsmi"
            validators={[VALIDATOR_REQUIRE()]}
            errorText="Fatura ismi giriniz."
            initialValue={loadedItem.name}
            initialValid={true}
            onInput={inputHandler}
            disabled
          />
          <Input
            id="passengeridentity"
            element="input"
            type="text"
            label="Fatura Kimlik No"
            validators={[VALIDATOR_REQUIRE()]}
            errorText="Kimlik no giriniz."
            initialValue={loadedItem.passengers[0].identity}
            initialValid={true}
            onInput={inputHandler}
            disabled
          />
          <Input
            id="address"
            element="input"
            type="text"
            label="Fatura Adresi"
            validators={[VALIDATOR_REQUIRE()]}
            errorText="Adres giriniz."
            initialValue={loadedItem.address}
            initialValid={true}
            onInput={inputHandler}
            disabled
          />
          <Input
            id="country"
            element="input"
            type="text"
            label="Fatura Ülkesi"
            validators={[VALIDATOR_REQUIRE()]}
            errorText="Ülke giriniz."
            initialValue={loadedItem.country}
            initialValid={true}
            onInput={inputHandler}
            disabled
          />
          {/* <div className="button-container">
            <Button type="submit" disabled={!formState.isValid}>
              Güncelle
            </Button>

            <Button type="button" danger onClick={showDeleteWarningHandler}>
              SİL
            </Button>
          </div> */}
        </form>
      )}
    </React.Fragment>
  );
};

export default TransferItem;
