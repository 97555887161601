import React, { useState, useContext } from "react";
import Button from "shared/components/FormElements/Button";
import Input from "shared/components/FormElements/Input";
import { AIRPORT_ID, tsp } from "shared/util/location";
import { CheckTimeString } from "shared/util/time";
import { sendFreeSMS } from "shared/util/util";
import { useHttpClient } from "shared/hooks/http-hook";
import { AuthContext } from "shared/context/auth-context";
import { StoreContext } from "shared/context/store-context";
import Modal from "shared/components/UIElements/Modal";
import LoadingSpinner from "shared/components/UIElements/LoadingSpinner";
import CustomSelect from "shared/components/FormElements/Select";
import { useForm } from "shared/hooks/form-hook";
import Accordion from "@mui/material/Accordion";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { VALIDATOR_REQUIRE, VALIDATOR_TIME } from "shared/util/validators";

const Personel = (props) => {
  const {
    users,
    shiftOptions,
    shifts,
    drivers,
    driverOptions,
    schoolOptions,
    UpdateShift,
    DeleteShift,
    shiftPersonelServiceOptions,
    shiftPersonelServices,
    userOptions,
    UpdatePersonelService,
    shiftUserOptions,
  } = useContext(StoreContext);

  const store = useContext(StoreContext);

  const [formStatePersonel, inputHandlerPersonel] = useForm(
    {
      personelUnique: { value: "", isValid: true },
    },
    true
  );

  const addUniquePersonel = async () => {
    try {
      props.addPersonel(formStatePersonel.inputs.personelUnique.value);
    } catch (err) {}
  };

  const getUserName = (id) => {
    const user = users.find((user) => user.id === id);
    return user.name;
  };

  const getUserService = (userId) => {
    const foundService = props.services.find((service) => service?.users?.includes(userId));
    if (foundService) {
      return foundService;
    }
    return null;
  };

  const getUserSmses = (userId) => {
    const foundService = getUserService(userId);
    if (foundService) {
      return foundService?.usersdetails?.find((user) => user.id === userId)?.smses || [];
    }
    return [];
  };

  const SendSMS = async (user) => {
    const text = document.getElementById("text" + user.id.toString().trim()).value;
    const phone = user.phone;
    // const date = new Date().toLocaleString();
    // const user_id = user.id;
    // const service = getUserService(user.id);
    const response = sendFreeSMS("5355802151", "test");
  };

  return (
    <div style={{ display: "flex", width: "100%", flexDirection: "column" }}>
      {/* <Modal
        show={showModal}
        onCancel={() => setShowModal(false)}
        header="Vardiya servisi."
        footerClass="place-item__modal-actions"
        footer={
          <React.Fragment>
            <Button inverse onClick={() => setShowModal(false)}>
              Tamam
            </Button>
          </React.Fragment>
        }
      >
        <p>{errorMessage}</p>
      </Modal> */}
      <div className="item-form">
        <CustomSelect
          options={shiftUserOptions}
          id="personelUnique"
          isMulti={false}
          initialValid={true}
          onInput={inputHandlerPersonel}
        ></CustomSelect>

        <Button type="button" danger onClick={addUniquePersonel.bind(this)}>
          Personel Ekle
        </Button>
      </div>
      <div style={{ marginTop: "20px" }}>
        {props.users &&
          props.services &&
          getUserService &&
          props.users.map((user) => (
            <Stack
              key={user.id}
              direction="column"
              alignItems="center"
              sx={{ width: "100%", marginBottom: "8px" }}
              spacing={3}
            >
              <Stack direction="row" alignItems="flex-start" sx={{ width: "100%" }} spacing={2}>
                <IconButton
                  aria-label="delete"
                  size="large"
                  onClick={() => props.removePersonel(user.id)}
                  sx={{ marginRight: "5px" }}
                >
                  <DeleteIcon fontSize="inherit" />
                </IconButton>

                <Accordion style={{ flex: 1, marginLeft: "0px" }}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                    {user.name} - {user.phonenumber} -{" "}
                    {getUserService(user.id)?.name || (
                      <span style={{ color: "red", marginLeft: "2px" }}>Servis kaydı yok</span>
                    )}
                  </AccordionSummary>
                  <AccordionDetails sx={{ paddingTop: "0px" }}>
                    <TableContainer
                      component={Paper}
                      sx={{
                        minWidth: 650,
                        margin: 0,
                        padding: 0,
                        //   marginBottom: "20px",
                        border: "none",
                        boxShadow: "none",
                        spacing: 0,
                        overflow: "visible",
                      }}
                    >
                      <Table
                        sx={{
                          minWidth: 650,
                          width: "100%",
                          justifyContent: "flex-start",
                          alignItems: "flex-start ",
                          // margin: "auto",
                          zIndex: -1,
                        }}
                        aria-label="simple table"
                      >
                        <TableBody>
                          <TableRow sx={{ verticalAlign: "initial", "td, th": { border: "none" } }}>
                            <TableCell component="th" width="35%" scope="row" sx={{ padding: 0 }}>
                              <CustomSelect
                                id={"personelservice" + user.id}
                                options={shiftPersonelServiceOptions.filter((serviceOption) =>
                                  props.services.map((service) => service.id).includes(serviceOption.value)
                                )}
                                isMulti={false}
                                onInput={inputHandlerPersonel}
                                initialValid={false}
                                initialValue={shiftPersonelServiceOptions.find(
                                  (option) => option.value === getUserService(user.id)?.id
                                )}
                                fireChange={props.changeUserService.bind(this, user.id)}
                              />
                            </TableCell>

                            <TableCell component="th" width="10%" scope="row" sx={{ paddingTop: 0, paddingBottom: 0 }}>
                              <Input
                                id={"personeltransfertime" + user.id}
                                element="input"
                                type="text"
                                onInput={inputHandlerPersonel}
                                initialValid={true}
                                // initialValue={GetGETONTransferTimeofUser(personelId)}
                                initialValue="15.35"
                                validators={[VALIDATOR_REQUIRE()]}
                                errorText="**:** formatında giriniz. Örnek: 07:00 ya da 18:35"
                              />
                            </TableCell>

                            <TableCell component="th" width="25%" scope="row" sx={{ padding: 0 }}>
                              {getUserSmses(user.id).length > 0 ? (
                                <div>
                                  {getUserSmses(user.id).map((sms) => (
                                    <div key={sms.id}>
                                      {sms.text}
                                      <br />
                                      <i>{sms.date}</i>
                                      <br />
                                      <br />
                                    </div>
                                  ))}
                                </div>
                              ) : (
                                <div>Mesaj Yok</div>
                              )}
                            </TableCell>

                            <TableCell scope="row" width="30%" align="right" sx={{ padding: 0 }}>
                              <div style={{ display: "flex", flexDirection: "column" }}>
                                <textarea
                                  style={{ margin: "16px", marginTop: "0px" }}
                                  key={"SMS" + user.id}
                                  id={"text" + user.id.toString().trim()}
                                  cols="30"
                                  rows="3"
                                  maxLength={155}
                                  defaultValue={`${props.shiftDate} tarihli ${props.shiftDisplayName} vardiyası için servis saatiniz yaklaşık 15:35'tir. İletişim için 05498003931.`}
                                ></textarea>
                                <div width="60px" style={{ marginRight: "16px" }}>
                                  <Button type="button" disabled danger onClick={() => SendSMS(user)}>
                                    SMS Gönder
                                  </Button>
                                </div>
                              </div>
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </AccordionDetails>
                </Accordion>
              </Stack>
            </Stack>
          ))}
      </div>
    </div>
  );
};

export default Personel;
