import { useContext } from "react";
import { useHttpClient } from "shared/hooks/http-hook";
import { AuthContext } from "shared/context/auth-context";
import { StoreContext } from "shared/context/store-context";
import { usertypes_website, usertypes_app_company, usertypes_app_school, days } from "./types";
import { getFormattedDate } from "./time";

export async function SendInstantSMS(smsData) {
  const auth = useContext(AuthContext);
  const { sendRequest } = useHttpClient();
  const APIEndPoint = "xrytutdvsdfds";

  try {
    await sendRequest(`${process.env.REACT_APP_BACKEND_URL}/${APIEndPoint}`, "POST", JSON.stringify(smsData), {
      "Content-Type": "application/json",
      Authorization: "Bearer " + auth.token,
    });
  } catch (err) {}
}

export async function sendFreeSMS(phone, text, token) {
  try {
    const messageResponse = await fetch(`${process.env.REACT_APP_BACKEND_URL}/gfhhdethjjdsd`, {
      method: "POST",
      body: JSON.stringify({ phone, text }),
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });

    return messageResponse?.sms || "";
  } catch (err) {}
}

export function isReceiptIntheSchool(receipt, schoolid, contracts) {
  if (!receipt.contract) {
  }

  const contract = contracts.find((c) => c.id.toString() === receipt.contract.toString());
  if (contract?.student?.school?.toString() === schoolid.toString()) {
    return true;
  } else {
    return false;
  }
}

export function isWithdrawIntheSchool(withdraw, schoolid, contracts) {
  const contract = contracts.find((c) => c.id.toString() === withdraw.contract.toString());
  if (contract?.student?.school?.toString() === schoolid.toString()) {
    return true;
  } else {
    return false;
  }
}

export function GenerateString(length) {
  var result = "";
  var characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

export function parseComplexDate(currentDate) {
  let myDate = new Date(currentDate);
  const offset = myDate.getTimezoneOffset();
  myDate = new Date(myDate.getTime() - offset * 60 * 1000);
  const finalDate = myDate.toISOString().split("T")[0];
  return finalDate;
}

export function generatePhoneNumber() {
  var result = "535";
  var characters = "0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < 7; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

export function GetCoordinates(location) {
  return {
    lat: 40.7484474,
    lng: -73.9871516,
  };
  // const [lat, lng] = location.split(';');

  // return {
  //   lat: Number(lat),
  //   lng: Number(lng)
  // };
}

// export function GetUserTypes(auth) {
//   if (auth && auth.schoolType === "1") {
//     return [...usertypesforschool];
//   } else if (auth && auth.schoolType === "2") {
//     return [...usertypes];
//   } else {
//     return [...usertypes];
//   }
// }

export function CheckAuthorization(userId, schoolType = "1") {
  const store = useContext(StoreContext);

  let usertypestoshow_website = [...usertypes_website];
  let usertypestoshow_app = [...usertypes_app_school];

  if (schoolType === "2") {
    usertypestoshow_app = [...usertypes_app_company];
  }

  let canUpdateContract = false;
  let accessToAccounting = false;
  let accessToTransfers = false;
  let isSchoolResponsible = false;
  let currentSchoolId = null;
  let accessToIkarus = false;
  let accessToAnalysis = false;
  let superUsers = [
    "61c006479a835c0016dbeb18",
    "61a4667dcbb1e600160a5c7c",
    "6266b4a9e2e756001619e8aa",
    "624ae1abbd512200165273d4",
    "632a5d91597ca55d441d7e2f",
    "632a5dc3597ca55d441d7e34",
  ];

  if (!store.vehicleRoutes) {
    return {
      canUpdateContract: canUpdateContract,
      accessToAccounting: accessToAccounting,
      accessToTransfers: accessToTransfers,
      isSchoolResponsible: isSchoolResponsible,
      currentSchoolId: currentSchoolId,
      accessToIkarus: accessToIkarus,
      accessToAnalysis: accessToAnalysis,
      superUsers: superUsers,
      usertypestoshow_website: usertypestoshow_website,
      usertypestoshow_app: usertypestoshow_app,
    };
  }

  const currentUser = store.users.find((user) => user.id === userId);

  const currentSchool = store.schools.find((school) => school.id.toString() === currentUser.school[0].toString());
  currentSchoolId = currentSchool.id;
  const userType = currentUser.usertype.toString();

  if (userType === "300") {
    //Muhasebe
    accessToAccounting = true;
    canUpdateContract = true;
  }

  if (userType === "100" && currentSchool.type.toString() === "2") {
    //Kurum Sorumlusu
    accessToTransfers = true;
    accessToIkarus = true;
    accessToAnalysis = true;
    usertypestoshow_website = usertypes_website.filter(
      (item) => item.value !== "1000" && item.value !== "300" && item.value !== "200"
    );
  }

  if ((userType === "100") & (currentSchool.type.toString() === "1")) {
    //Okul sorumlusu
    isSchoolResponsible = true;
    accessToAccounting = false;
  }

  if (userType === "200") {
    //Şirket Admini
    usertypestoshow_website = usertypes_website.filter((item) => item.value !== "1000" && item.value !== "300");
  }

  if (userType === "1000") {
    //Genel Admin Servistesin
    canUpdateContract = true;
    accessToAccounting = true;
    accessToTransfers = true;
    accessToIkarus = true;
    accessToAnalysis = true;
  }

  const Authorization = {
    canUpdateContract: canUpdateContract,
    accessToAccounting: accessToAccounting,
    accessToTransfers: accessToTransfers,
    isSchoolResponsible: isSchoolResponsible,
    currentSchoolId: currentSchoolId,
    accessToIkarus: accessToIkarus,
    accessToAnalysis: accessToAnalysis,
    superUsers: superUsers,
    usertypestoshow_website: usertypestoshow_website,
    usertypestoshow_app: usertypestoshow_app,
  };

  return Authorization;
}

// turns query string back into an object
export function queryStringToObject(queryString = "", options = {}) {
  let queryObject = {};
  queryString &&
    decodeURIComponent(queryString.replace("?", ""))
      .split("&")
      .forEach((itemString) => {
        let [itemKey, itemValue] = itemString.split("=");
        if (options.hasOwnProperty(itemKey)) {
          if (!queryObject[itemKey] && Array.isArray(options[itemKey])) {
            queryObject[itemKey] = [];
          }
          Array.isArray(options[itemKey])
            ? queryObject[itemKey].push(itemValue)
            : (queryObject[itemKey] = typeof options[itemKey] === "number" ? parseInt(itemValue) : itemValue);
        }
      });
  return queryObject;
}

export function getShiftDate(shift) {
  const todayDate = new Date();
  const todayInt = parseInt(getCurrentDayNumber());
  const shiftDayInt = parseInt(shift.day);

  let shiftDate = new Date(todayDate);
  if (shiftDayInt >= todayInt) {
    shiftDate.setDate(todayDate.getDate() + (shiftDayInt - todayInt));
  } else {
    shiftDate.setDate(todayDate.getDate() + (7 - todayInt + shiftDayInt));
  }
  return getFormattedDate(shiftDate);
}

export function getCurrentDayNumber() {
  const dayOfWeek = new Date().getDay();
  if (dayOfWeek === 0) {
    return "7";
  } else {
    return dayOfWeek.toString();
  }
}

export function sortShifts(shifts) {
  const today = parseInt(getCurrentDayNumber());
  //sort shift by shift.day but use currentDayNumber as the first day but for example current day can be 3 in this case it should sort as 3,4,5,6,7,1,2
  const numericDays = days.map((day) => parseInt(day.value));

  // const shiftsBeforeToday = shifts.filter((shift) => parseInt(shift.day) < today);
  // const shiftsAfterToday = shifts.filter((shift) => parseInt(shift.day) >= today);
  //add endtime value to the above filters. if endtime of shift has passed it should be in the beforeToday array
  // rewrite the above filters
  const currentTime = new Date().getHours() * 60 + new Date().getMinutes();
  const shiftsBeforeToday = shifts.filter(
    (shift) =>
      parseInt(shift.day) < today ||
      (parseInt(shift.day) === today &&
        parseInt(shift.endtime.split(":")[0]) * 60 + parseInt(shift.endtime.split(":")[1]) < currentTime)
  );
  const shiftsAfterToday = shifts.filter(
    (shift) =>
      parseInt(shift.day) >= today &&
      (parseInt(shift.day) !== today ||
        parseInt(shift.endtime.split(":")[0]) * 60 + parseInt(shift.endtime.split(":")[1]) >= currentTime)
  );

  // Sort the arrays
  shiftsBeforeToday.sort((a, b) => parseInt(a.day) - parseInt(b.day));
  shiftsAfterToday.sort((a, b) => parseInt(a.day) - parseInt(b.day));

  // Concatenate the arrays, starting from today and wrapping around from 7 to 1
  return shiftsAfterToday.concat(shiftsBeforeToday);
}

export async function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}
