import React, { useCallback, useState, useContext } from "react";
import { NavLink } from "react-router-dom";

import CompaniesList from "../components/company/CompaniesList";
import CompanyItem from "../components/company/CompanyItem";
import ErrorModal from "shared/components/UIElements/ErrorModal";
import LoadingSpinner from "shared/components/UIElements/LoadingSpinner";
import { useHttpClient } from "shared/hooks/http-hook";
import { StoreContext } from "shared/context/store-context";
import "./Admin.css";

const Companies = () => {
  const store = useContext(StoreContext);
  const { isLoading, error, clearError } = useHttpClient();
  const [selectedItemId, setSelectedItemId] = useState();

  const pageItems = store.companies;
  const newItemLink = "new/company";
  const ItemTypeTR = "Servis Firması";

  const ItemSelectHandler = useCallback((itemid) => {
    setSelectedItemId(itemid);
  }, []);

  const ItemUpdateHandler = useCallback((itemid) => {
    setSelectedItemId(itemid);
  }, []);

  return (
    <React.Fragment>
      <ErrorModal error={error} onClear={clearError} />
      {isLoading && (
        <div className="center">
          <LoadingSpinner />
        </div>
      )}

      <div className="adminNavigationHeader">
        <NavLink to={newItemLink} exact>
          Yeni {ItemTypeTR}
        </NavLink>
      </div>
      <div className="adminpageContainer">
        {!isLoading && pageItems && (
          <div className="adminListContainer">
            <CompaniesList items={pageItems} onItemSelect={ItemSelectHandler} />
          </div>
        )}
        {selectedItemId && (
          <div className="adminUpdateContainer">
            <CompanyItem itemid={selectedItemId} onItemUpdate={ItemUpdateHandler}></CompanyItem>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default Companies;
