import React, { useEffect, useState, useContext, useRef } from "react";
import Button from "shared/components/FormElements/Button";
import { HTML5Backend } from "react-dnd-html5-backend";
import Input from "shared/components/FormElements/Input";
import { AIRPORT_ID, tsp } from "shared/util/location";
import { CheckTimeString } from "shared/util/time";
import ShiftDragger from "./dragger/ShiftDragger";
import { sendFreeSMS } from "shared/util/util";
import { useHttpClient } from "shared/hooks/http-hook";
import { AuthContext } from "shared/context/auth-context";
import { StoreContext } from "shared/context/store-context";
import Modal from "shared/components/UIElements/Modal";
import LoadingSpinner from "shared/components/UIElements/LoadingSpinner";
import CustomSelect from "shared/components/FormElements/Select";
import { useForm } from "shared/hooks/form-hook";
import Accordion from "@mui/material/Accordion";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import ServiceItem from "./ServiceItem";
import { DndProvider } from "react-dnd";
import { VALIDATOR_REQUIRE, VALIDATOR_NONE } from "shared/util/validators";

const Personel = (props) => {
  const {
    users,
    shiftOptions,
    shifts,
    drivers,
    driverOptions,
    schoolOptions,
    UpdateShift,
    DeleteShift,
    shiftPersonelServiceOptions,
    shiftPersonelServices,
    userOptions,
    UpdatePersonelService,
    InsertPersonelService,
  } = useContext(StoreContext);

  const auth = useContext(AuthContext);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();

  const [isProcessing, setIsProcessing] = useState(false);
  const [processId, setProcessId] = useState(null);
  const isProcessingRef = useRef(false);
  const processIdRef = useRef(null);
  const isFetchingRef = useRef(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const [analysingServices, setAnalysingServices] = useState([]);
  const [distances, setDistances] = useState([]);

  const createServices = async () => {
    const data = {
      school: props.school,
      shift: props.shift,
      type: props.type,
      users: props.users,
    };

    let distributionProcessId = null;
    try {
      const response = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/automated/createservices`,
        "POST",
        JSON.stringify(data),
        {
          "Content-Type": "application/json",
          Authorization: "Bearer " + auth.token,
        }
      );

      distributionProcessId = response.process.id;

      if (!distributionProcessId) {
        return;
      }

      setIsProcessing(true);
      isProcessingRef.current = true;
      setProcessId(distributionProcessId);
      processIdRef.current = distributionProcessId;
      // InsertPersonelService(responseData.personelservice);
      // setServicesInTheShift([...servicesIntheShift, ...responseData.personelservice]);
    } catch (err) {}
  };

  const getServiceResults = (response) => {
    const transfertime = props.type === "100" ? props.shift.starttime : props.shift.stoptime;
    const strings_transferdate_month = props.shiftDate.split("-")[1];
    const strings_transferdate_day = props.shiftDate.split("-")[2];

    let createdServices = [];

    if (response?.data?.services) {
      for (let i = 0; i < response.data.services.length; i++) {
        const currentServiceName =
          strings_transferdate_day + "." + strings_transferdate_month + "-" + transfertime + " --- " + (i + 1);
        const currentServiceId = Math.random().toString(36).substring(7);
        createdServices.push({
          ...response.data.services[i],
          type: props.type,
          isShift: true,
          school: props.school.id,
          name: currentServiceName,
          plate: "",
          starttime: props.shift.starttime,
          stoptime: props.shift.stoptime,
          date: props.shiftDate,
          drivers: ["61aee5393958380016e7db4b"],
          id: currentServiceId,
        });
      }

      setAnalysingServices(createdServices);
      setDistances(response.data.distancesInformation);
    }
  };

  useEffect(() => {
    const fetchProcessState = async () => {
      if (!processIdRef.current || !isProcessingRef.current || isFetchingRef.current) {
        return;
      }
      isFetchingRef.current = true;
      try {
        const resp = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/process/${processIdRef.current}`,
          "GET",
          null,
          { Authorization: "Bearer " + auth.token }
        );

        if (resp?.process?.processing === false && resp?.process?.errorCode === 0) {
          getServiceResults(resp.process);
        } else if (resp?.process?.processing === false && resp?.process?.errorCode === 1) {
          setErrorMessage(resp.process.error);
        }
        isProcessingRef.current = resp.process.processing;
        setIsProcessing(resp.process.processing);
      } catch (err) {}
      isFetchingRef.current = false;
    };
    fetchProcessState();

    const intervalId = setInterval(fetchProcessState, 5000);

    return () => {
      clearInterval(intervalId);
    };
  }, [processId, sendRequest, auth.token]);

  return (
    <div style={{ display: "flex", width: "100%", flexDirection: "column" }}>
      <div style={{ marginBottom: "20px" }}>
        <Button type="button" danger disabled={isProcessing} onClick={createServices}>
          Servisleri Oluştur
        </Button>
      </div>

      {isProcessing && (
        <div style={{ marginTop: "20px", display: "flex", flexDirection: "row", alignItems: "center" }}>
          <LoadingSpinner />
          <span> Servisler oluşturuluyor...</span>
        </div>
      )}

      {analysingServices && analysingServices?.length > 0 && distances && distances.length && (
        <DndProvider backend={HTML5Backend}>
          <ShiftDragger
            school={props.school}
            shift={props.shift}
            type={props.type}
            shiftDate={props.shiftDate}
            users={props.users}
            services={analysingServices}
            distances={distances}
            // transfers={plane.transfers}
            // onTransferCreated={() => handleTransferCreated(plane.planeName, plane.planeDepartureTime)}
          />
        </DndProvider>
      )}
    </div>
  );
};

export default Personel;
